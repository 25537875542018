import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Table, Skeleton } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';

import OverviewHeader from 'common/OverviewHeader';
import { ReactComponent as ZohoIcon } from 'assets/images/icons/zoho.svg';
import AntPagination from 'common/AntTablePagination';
import utils, {  getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import { actions as loadingUnloadingActions } from 'redux/loadingUnloading';
import { Link, useHistory } from 'react-router-dom';
import FilterUtils from 'lib/filterUtils';
import useQuery from 'lib/hooks/useQuery';
import dayjs from 'dayjs';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import OverviewNav from 'common/OverviewNav';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import TableListHeader from 'common/TableListHeader';
import { getLinks } from 'constants/linkConfig';

const ZohoLogs = () => {
  const { page = 1, payment_number, sync_date, payment_master_id, application_method, result, activeTab, zoho_payment_type_enum } = useQuery();

  const dispatch = useDispatch();
  const tableRef = useRef();
  const zohoState = useSelector((state) => state.loadingUnloading);
  const zohoDetails = zohoState?.zohoDetails;
  const busy = zohoState?.busy;
  const userInfo = useSelector((state) => state.user);
  const context = useSelector((state) => state.context);
  const isControlTowerUser = _.get(userInfo, 'profile.person.is_control_tower_user') || false;
  const typeOfIndividualEnum = _.get(userInfo, 'profile.person.type_of_individual_enum') || 0;
  const isTransporter = _.get(userInfo, 'profile.person.is_transporter') || false;
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);
  const [links, setLinks] = useState([]);
  const [activeTabTemp, setActiveTabTemp] = useState('');
  const isSupplyPartner = _.get(userInfo, 'profile.is_supply_partner') || false

  const back = utils.encodeBackUrl();
  const history = useHistory();
  const loc =  getDecryptedUrl(window.location.href);
  const is_zoho_connected = userInfo?.profile?.is_connected;

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  useEffect(() => {
    const params = {
      filters: filters,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(loadingUnloadingActions.fetchZohoPaymentLog(params));
  }, [page, filters, rowsPerPage]);

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }

    setFilters(params);
    history.push(FilterUtils.createUrl({ ...params, activeTab: activeTabTemp }));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const TABLE_HEADERS = [
    {
      title: 'Payment No.',
      dataIndex: 'payment_number',
      width: '120px',
      align: 'center',
      fixed: true,
      render: (text, row) => (
        <div className="bfc-table-list-components">
          <div className="commodity-form text-center">
            {row.payment_number}
          </div>
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'payment_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_number}
          name={'Form'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'payment_number'),
      filteredValue: payment_number !== undefined ? payment_number : null,
    },
    {
      title: 'Payment Id',
      dataIndex: 'payment_master_id',
      width: '80px',
      align: 'center',
      fixed: true,
      render: (text, row) => (
        <div className="bfc-table-list-components">
          <Link to={getUrlWithEncryptedQueryParams(`/payment/${row?.payment_master_id}/overview?back=${back}`)}>{row.payment_master_id}</Link>
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'payment_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_master_id}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'payment_master_id'),
      filteredValue: payment_master_id !== undefined ? payment_master_id : null,
    },
    {
      title: 'Payment Type',
      dataIndex: 'zoho_payment_type_enum',
      width: '80px',
      align: 'left',
      fixed: true,
      render: (text, row) => (
        <div className="text-left">
          {row?.zoho_payment_type_enum_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'zoho_payment_type_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={zoho_payment_type_enum}
          name={'Payment Type'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'zoho_payment_type_enum'),
      filteredValue: zoho_payment_type_enum !== undefined ? zoho_payment_type_enum : null,
    },
    {
      title: 'Applications Method',
      dataIndex: 'application_method',
      width: '120px',
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components">{row?.application_method}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'application_method'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={application_method}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'application_method'),
      filteredValue: application_method !== undefined ? application_method : null,
    },
    {
      title: 'Exception',
      dataIndex: 'result',
      width: '120px',
      align: 'left',
      render: (text, row) => <div className="bfc-table-list-components text-left">{row?.result}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'result'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={result}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'result'),
      filteredValue: result !== undefined ? result : null,
    },
    {
      title: 'Sync Date',
      dataIndex: 'sync_date',
      width: '120px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components">
          {row?.sync_date ? dayjs(row?.sync_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'sync_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={sync_date}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'sync_date'),
      filteredValue: sync_date !== undefined ? sync_date : null,
    },
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl({ ...params, activeTab: activeTabTemp }));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(getUrlWithEncryptedQueryParams(pathname + '?' + `page=1` + '&' + `activeTab=${activeTabTemp}`));
  };

  const totalRecords = _.get(zohoDetails, `[0].total_count`) || 0;

  // let links = [];
  // links = [
  //   { to: '/market/invoices?page=1', title: 'Invoice' },
  //   { to: '/market/payments?page=1', title: 'Payment' },
  //   { to: '/market/cashflow?page=1', title: 'Cashflow' },
  // ];
  // if (isControlTowerUser && loc.includes('controltower')) {
  //   links = [
  //     { to: '/controltower/invoices?page=1', title: 'Invoice' },
  //     { to: '/controltower/payments?page=1', title: 'Payment' },
  //     { to: '/controltower/manual-invoice?page=1', title: 'Manual Invoice' },
  //   ];
  // }
  // if (isTransporter || typeOfIndividualEnum == 8) {
  //   if (context.ctx == '3') {
  //     links = [
  //       { to: '/network/invoices?page=1', title: 'Invoice' },
  //       { to: '/network/payments?page=1', title: 'Payment' },
  //     ];
  //   }
  // }

  // if (typeOfIndividualEnum === 7) {
  //   if (context.ctx == '3') {
  //     links = [{ to: '/network/payments?page=1', title: 'Payment' }];
  //   }
  // }

  // if (activeTab == "market") {
  //   links.push({ to: `/market/invoices?page=1`, title: 'Invoice' })
  //   links.push({ to: `/market/payments?page=1`, title: 'Payment' })
  //   links.push({ to: `/market/cashflow?page=1`, title: 'Cashflow' })
  //   links.push({ to: `/market/ledger?page=1`, title: 'Ledger' })
  // } else if (activeTab == "network") {
  //   links.push({ to: `/network/ledger?page=1`, title: 'Ledger' })
  // } else if (activeTab == "CT") {
  //   links.push({ to: `/controltower/invoices?page=1`, title: 'Invoice' })
  //   links.push({ to: `/controltower/payments?page=1`, title: 'Payment' })
  //   links.push({ to: '/controltower/manual-invoice?page=1', title: 'Manual Invoice' })
  //   links.push({ to: `/control-tower/ledger?page=1`, title: 'Ledger' })
  // }
  // if(is_zoho_connected) {
  //   links = [...links, { title: 'Zoho Invoice Log', to: `/configuration/zoho-logs?page=1&activeTab=${activeTab}` }, { title: 'Zoho Payment Log', to: `/configuration/zoho_payment-log?page=1&activeTab=${activeTab}` }]
  // }

  useEffect(() => {
    const fetchedLinks = (isSupplyPartner,isControlTowerUser, isTransporter, typeOfIndividualEnum, context, loc, is_zoho_connected, activeTab);
    setLinks(fetchedLinks);
  }, [loc, context.ctx]);

  useEffect(() => {
    if (context.ctx === '2') {
      setActiveTabTemp('market');
    } else if (context.ctx === '4') {
      setActiveTabTemp('network');
    } else if (context.ctx === '5') {
      setActiveTabTemp('CT');
    }
  }, [context.ctx])

  return (
    <div>
      {/* <OverviewNav links={links} /> */}
      <div className="bfc-header">
        <TableListHeader
          links={links} />
      </div>
      <div className="bfc-body">
        {/* {busy && (
          <div className="commodity-wrapper">
            <div className="panel__refresh">
              <LoadingIcon />
            </div>
          </div>
        )} */}
        <div className="blocked-table">
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? zohoDetails : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 550 }}
              className="ant-table"
              size="small"
            />
            {totalRecords > 0 && (
              <AntPagination
                total={parseInt(totalRecords)}
                handlePageChange={handlePageChange}
                page={page}
                clearFilters={clearFilters}
                rowsPerPage={rowsPerPage}
                setScrollToFirstRow={setScrollToFirstRow}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ZohoLogs;
