import React, { ElementType, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colord as colorFormat } from 'colord';
import { getIn } from 'formik';
import { Tooltip } from 'antd';

import getColor from '../colors';
import { actions as MUIAutocompleteAction } from 'redux/MUIComponentReducers/MUIAutocompleteReducer';
import MUITypography from '../Typography';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

type styleType = {
  color: string;
  textColor: string;
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    '& label.Mui-focused': {
      color: (props: styleType) => props?.color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: (props: styleType) => props?.color,
    },
    '& .MuiInputBase-root:has(.Mui-disabled):hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
    '& fieldset > legend': {
      margin: '0 !important',
    },
    '& [class*="MuiAutocomplete-hasLabel"] fieldset > legend > span': {
      paddingRight: 7,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (props: styleType) => props?.color,
      },
      '&:hover fieldset': {
        borderColor: (props: styleType) => props?.color,
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: styleType) => props?.color,
      },
      '& input': {
        letterSpacing: '1px',
        textTransform: 'capitalize',
      },
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '6px !important',
    },
    '& .MuiChip-root': {
      backgroundColor: (props: styleType) => props?.color,
      color: (props: styleType) => props?.textColor,
      letterSpacing: '1px',
      textTransform: 'capitalize',
      '& .MuiChip-deleteIcon': {
        fill: (props: styleType) => props?.textColor,
      },
    },
    '& .MuiInputLabel-root': {
      letterSpacing: '1px',
      fontFamily: 'Segoe UI',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -6px) scale(0.70) !important',
      color: (props: styleType) => getColor(`${props?.color}`),
    },
    '& .MuiInputLabel-shrink': {
      color: (props: styleType) => getColor(`${props?.color}`),
      fontWeight: 600,
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after':
      {
        borderColor: (props: styleType) => getColor(`${props?.color}`),
      },
    '& .MuiFilledInput-root': {
      background: (props: styleType) =>
        `${colorFormat(getColor(`${props?.color}`))
          .alpha(0.09)
          .toHex()} !important`,
      '&:hover': {
        background: (props: styleType) =>
          `${colorFormat(getColor(`${props?.color}`))
            .alpha(0.13)
            .toHex()} !important`,
      },
    },
    '& .MuiOutlinedInput-root:has(.MuiInputAdornment-positionStart) legend': {
      marginLeft: '42px !important',
      maxWidth: '0px !important',
      transitionDuration: '0ms !important',
      transitionDelay: '0ms !important',
    },
    '& .MuiInputLabel-shrink:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      transform: 'translate(56px, -6px) scale(0.70) !important',
    },
    '& .MuiInputLabel-root:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      transform: 'translate(55px, 12px) scale(1) !important',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputLabel-root.Mui-focused:has(+ .MuiOutlinedInput-root .MuiInputAdornment-positionStart)': {
      color: (props: styleType) => getColor(`${props?.color}`),
      transform: 'translate(56px, -6px) scale(0.70) !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused legend': {
      maxWidth: '1000px !important',
      margin: '0 2px',
    },
    '& .MuiFormHelperText-contained': {
      margin: '4px 0 0 2px',
    },
    '& .MuiCircularProgress-svg': {
      color: (props: styleType) => getColor(`${props?.color}`),
    },
  },
  selectedOption: {
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.09).toHex()} !important`,
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.09).toHex()} !important`,
    },
    '& .MuiIconButton-root': {
      color: (props: styleType) => `${props?.color} !important`,
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.04).toHex()} !important`,
    },
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 5px ${(props: styleType) => props?.color}`,
      borderRadius: '10px',
      backgroundColor: (props: styleType) => `${colorFormat(props?.color).alpha(0.13).toHex()} !important`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props: styleType) => props?.color,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: (props: styleType) => colorFormat(props?.color).darken(50),
    },
  },
  inputRoot: {
    '&:not(.Mui-focused)': {
      flexWrap: 'nowrap',
    },
    '&[class*="MuiAutocomplete-hasClearIcon"]:hover .MuiAutocomplete-popupIndicator, &[class*="MuiAutocomplete-hasClearIcon"].Mui-focused .MuiAutocomplete-popupIndicator':
      {
        opacity: 0,
        transform: 'scale(0.8)',
        transition: 'opacity 0.4s ease, transform 0.4s ease',
      },
    '&[class*="MuiAutocomplete-hasClearIcon"]:hover .MuiAutocomplete-clearIndicator, &[class*="MuiAutocomplete-hasClearIcon"].Mui-focused .MuiAutocomplete-clearIndicator':
      {
        opacity: 1,
        transform: 'scale(1)',
        transition: 'opacity 0.4s ease, transform 0.4s ease',
        zIndex: 1,
      },
  },
  popupIndicator: {
    right: 0,
    opacity: 1,
    position: 'absolute',
    transform: 'scale(1)',
    transition: 'opacity 0.4s ease, transform 0.4s ease',
  },
  clearIndicator: {
    position: 'absolute',
    right: 0,
    opacity: 0,
    transform: 'scale(0.8)',
    transition: 'opacity 0.4s ease, transform 0.4s ease',
  },
}));

const getSelectedItem = (
  type,
  multiselectArray,
  formattedDropdownData,
  selectedCodeOrId,
  autoSelectOption,
  autoSelectOptionByIndex,
) => {
  const multiselectSet = new Set(
    multiselectArray.map((item) => (typeof item === 'object' ? item.value || item.label : item)),
  );
  if (type === 'multiselect' && multiselectArray.length && formattedDropdownData?.length) {
    return formattedDropdownData.filter((item) => multiselectSet.has(item.value) || multiselectSet.has(item.label));
  }
  if (
    (type === 'normal' || type === 'search') &&
    formattedDropdownData.length &&
    selectedCodeOrId &&
    !autoSelectOption
  ) {
    return formattedDropdownData.find((item) => item.value == selectedCodeOrId || item.label === selectedCodeOrId);
  }
  if (
    (type === 'normal' || type === 'search') &&
    formattedDropdownData.length &&
    autoSelectOption &&
    (autoSelectOptionByIndex || autoSelectOptionByIndex === 0)
  ) {
    return formattedDropdownData?.[autoSelectOptionByIndex];
  }
  return selectedCodeOrId;
};

type MUIAutocompletePropTypes = {
  type?: 'normal' | 'multiselect' | 'search';
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'standard' | 'filled';
  color?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string;
  data?: [];
  selectedCodeOrId: string | number;
  dataStructure: { labelApiParam: string; valueApiParam: string | number };
  apiParams: { reqUrl: string; method: string; isQueryParams?: boolean; getReqParams?: (value: string) => {} };
  loading?: boolean;
  open?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  needAllOption?: boolean;
  StartIcon?: ElementType | string;
  LabelComponent?: ElementType | React.ComponentType<any>;
  startIconStyles?: React.CSSProperties;
  EndIcon?: ElementType | string;
  endIconStyles?: React.CSSProperties;
  field?: any;
  form?: any;
  isError?: boolean;
  errorMsg?: string;
  onChange?: (value: any, setterFunction: Function) => void;
  generateEndContent?: (value: any) => HTMLElement;
  getSanitizedData?: (value: []) => [];
  onInputChange?: (value: string, reason: 'input' | 'reset' | 'clear') => void;
  multiselectArray?: Array<number>;
  limitTags?: number;
  checkBoxSize?: 'small' | 'medium';
  disableClearable?: boolean;
  areRequiredApiParamsPresent?: boolean;
  dependentApiParams?: string | number | boolean;
  dataStoreEntityName?: string;
  callOnChangeAgain?: boolean;
  showValueInsteadOfLabelOnChip?: boolean;
  showRequiredStar?: boolean;
  autoSelectOption?: boolean;
  autoSelectOptionByIndex?: number;
  tooltipStyles?: React.CSSProperties;
  tooltipPlacement?:
    | 'top'
    | 'topLeft'
    | 'topRight'
    | 'right'
    | 'rightTop'
    | 'rightBottom'
    | 'bottom'
    | 'bottomLeft'
    | 'bottomRight'
    | 'left'
    | 'leftTop'
    | 'leftBottom';
};

const MUIAutocomplete = ({
  type = 'normal',
  size = 'small',
  variant = 'outlined',
  color = 'muiPrimary',
  data,
  selectedCodeOrId,
  apiParams,
  dataStructure,
  loading,
  label,
  placeholder,
  disabled = false,
  fullWidth = true,
  StartIcon = '',
  startIconStyles = {},
  EndIcon = '',
  endIconStyles = {},
  field,
  form,
  isError = false,
  errorMsg = '',
  onChange,
  generateEndContent,
  getSanitizedData,
  onInputChange,
  multiselectArray = [],
  limitTags = 2,
  checkBoxSize = 'medium',
  disableClearable,
  areRequiredApiParamsPresent = true,
  dependentApiParams = false,
  dataStoreEntityName,
  callOnChangeAgain = false,
  showValueInsteadOfLabelOnChip = false,
  tooltipStyles = {},
  open,
  LabelComponent,
  showRequiredStar = false,
  autoSelectOption = false,
  autoSelectOptionByIndex,
  needAllOption,
  ...rest
}: MUIAutocompletePropTypes) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config');
  const bgColor = getColor(config?.theme_color || color);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();
  const classes = useStyles({ color: bgColor, textColor });

  const entity = dataStoreEntityName
    ? dataStoreEntityName
    : apiParams?.reqUrl?.split('/')?.[apiParams?.reqUrl.split('/').length - 1];
  const dropdownData = entity ? _.get(state, `MUIComponents.data.${entity}`, data || []) : data;
  const busy = _.get(state, `MUIComponents.loading.${entity}`);

  const [selectedValue, setSelectedValue] = useState<object | object[]>(type === 'multiselect' ? [] : {});
  const [searchValue, setSearchValue] = useState('');

  const hasError = isError || getIn(form?.touched, field?.name) || getIn(form?.errors, field?.name);
  const errText = hasError ? errorMsg || getIn(form?.errors, field?.name) : '';

  const { method, reqUrl, getReqParams, isQueryParams } = apiParams;
  useEffect(() => {
    if (type === 'search' && areRequiredApiParamsPresent && (searchValue || selectedCodeOrId)) {
      const params = { method, reqUrl, isQueryParams, reqParams: getReqParams && getReqParams(searchValue) };
      dispatch(MUIAutocompleteAction.getDataFromApi(params, entity));
    } else if (type !== 'search' && areRequiredApiParamsPresent && !_.isEmpty(apiParams)) {
      const params = { method, reqUrl, isQueryParams, reqParams: getReqParams ? getReqParams(searchValue) : {} };
      dispatch(MUIAutocompleteAction.getDataFromApi(params, entity));
    }
  }, [searchValue, reqUrl, areRequiredApiParamsPresent, dependentApiParams]);

  const labelParams = dataStructure?.labelApiParam?.includes('-')
    ? dataStructure?.labelApiParam?.split('-')
    : dataStructure?.labelApiParam;
  const generateLabel = (labelData) => {
    return typeof labelParams === 'object'
      ? labelParams?.map((item) => `${labelData?.[item]}`)?.join(' - ')
      : labelData?.[labelParams] || '';
  };

  const getFormattedData = (data) => {
    return (
      data?.length &&
      data?.map((item) => ({
        ...item,
        label: generateLabel(item),
        value: item?.[`${dataStructure?.valueApiParam}`],
      }))
    );
  };

  // let formattedDropdownData = dropdownData?.map((item) => ({
  //   ...item,
  //   label: generateLabel(item),
  //   value: item?.[`${dataStructure?.valueApiParam}`],
  // }));

  let formattedDropdownData = [
    ...(needAllOption ? [{ label: 'Select All', value: null }] : []),
    ...dropdownData?.map((item) => ({
      ...item,
      label: generateLabel(item),
      value: item?.[`${dataStructure?.valueApiParam}`],
    })),
  ];

  // let formattedDropdownData = getFormattedData(dropdownData);

  if (getSanitizedData && formattedDropdownData?.length) {
    const sanitizedData = getSanitizedData(formattedDropdownData);
    // formattedDropdownData = getFormattedData(sanitizedData);
    formattedDropdownData = sanitizedData;
  }

  const selected = useMemo(
    () =>
      getSelectedItem(
        type,
        multiselectArray,
        formattedDropdownData,
        selectedCodeOrId,
        autoSelectOption,
        autoSelectOptionByIndex,
      ),
    [type, multiselectArray, formattedDropdownData, selectedCodeOrId],
  );

  useEffect(() => {
    if (!_.isEmpty(selected) && (_.isEmpty(selectedValue) || autoSelectOption)) {
      setSelectedValue(selected);
      if (callOnChangeAgain || autoSelectOption) {
        form?.setFieldValue(field?.name, selected?.[`${dataStructure?.valueApiParam}`]);
        onChange && onChange(selected, setSelectedValue);
      }
    } else if (_.isEmpty(selected)) {
      setSelectedValue({});
    }
  }, [!_.isEmpty(selected), selected?.[`${dataStructure?.valueApiParam}`]]);

  useEffect(() => {
    return () => {
      dispatch(MUIAutocompleteAction.setData({ entity, resData: [] }));
    };
  }, []);

  const tooltipStyle: React.CSSProperties | undefined = {
    padding: '12px 16px',
    color: 'rgba(0, 0, 0, 0.85)',
    borderRadius: 15,
    boxShadow:
      '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    lineHeight: 1.5715,
    ...tooltipStyles,
  };

  return (
    <Autocomplete
      aria-expanded={false}
      size={size}
      open={open}
      fullWidth={fullWidth}
      disabled={disabled || !areRequiredApiParamsPresent}
      value={_.isEmpty(selectedValue) ? (type === 'multiselect' ? [] : null) : selectedValue}
      options={formattedDropdownData}
      multiple={type === 'multiselect'}
      limitTags={limitTags}
      disableCloseOnSelect={type === 'multiselect'}
      disableClearable={disableClearable}
      getOptionLabel={(option) => option?.['label'] || ''}
      getOptionSelected={(option, value) =>
        !_.isEmpty(option) && !_.isEmpty(value) ? option?.['value'] === value?.['value'] : null === null
      }
      classes={{
        listbox: classes.selectedOption,
        inputRoot: `${classes.inputRoot} ${
          !_.isEmpty(selectedValue) ? `MuiAutocomplete-hasClearIcon ${label ? 'MuiAutocomplete-hasLabel' : ''}` : ''
        }`,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
      }}
      loading={loading || busy}
      renderTags={(value, getTagProps) => {
        if (type === 'multiselect') {
          return value?.map((option, index) => (
            <Tooltip
              key={option?.['value']}
              title={
                showValueInsteadOfLabelOnChip ? (
                  <MUITypography color="#000" variant="body2">
                    {option?.['label']}
                  </MUITypography>
                ) : (
                  false
                )
              }
              color={tooltipStyle?.backgroundColor}
              overlayInnerStyle={tooltipStyle}
              overlayStyle={{ maxWidth: tooltipStyle?.maxWidth || tooltipStyle?.width || 300 }}
              placement={rest?.tooltipPlacement}
            >
              <Chip
                size={size}
                label={showValueInsteadOfLabelOnChip ? option?.['value'] : option?.['label']}
                {...getTagProps({ index })}
              />
            </Tooltip>
          ));
        }
        return null;
      }}
      renderOption={(option, { selected }) => {
        return LabelComponent ? (
          <LabelComponent option={option} />
        ) : (
          <div
            className="w-100 d-flex justify-content-between align-items-center"
            style={{ letterSpacing: 1, textTransform: 'capitalize', overflowWrap: 'anywhere' }}
          >
            <div className="d-flex align-items-center">
              {type === 'multiselect' ? (
                <Checkbox
                  size={checkBoxSize}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 0 }}
                  checked={selected}
                />
              ) : (
                ''
              )}
              {StartIcon ? (
                <>
                  {typeof StartIcon === 'string' ? (
                    <span style={{ color: bgColor, marginRight: 8 }}>{StartIcon}</span>
                  ) : (
                    <StartIcon
                      style={{
                        color: bgColor,
                        height: 24,
                        width: 24,
                        fill: bgColor,
                        marginRight: 8,
                        ...startIconStyles,
                      }}
                    />
                  )}
                </>
              ) : (
                ''
              )}
              <span style={{ textTransform: 'capitalize' }}>{option?.['label']}</span>
            </div>
            <div className="d-flex align-items-center">
              {EndIcon ? (
                <>
                  {typeof EndIcon === 'string' ? (
                    <div style={{ color: bgColor, marginRight: 10 }}>{EndIcon}</div>
                  ) : (
                    <EndIcon style={{ height: 24, width: 24, ...endIconStyles }} />
                  )}
                </>
              ) : (
                ''
              )}
              <div style={{ color: bgColor }}>{generateEndContent && generateEndContent(option)}</div>
            </div>
          </div>
        );
      }}
      // onChange={(e, newValue) => {
      //   console.log("OmkarNewValue", newValue)
      //   setSelectedValue(newValue!);
      //   form && form?.setFieldValue(field?.name, newValue?.[`${dataStructure?.valueApiParam}`]);
      //   onChange && onChange(newValue, setSelectedValue);
      // }}
      // onChange={(e, newValue) => {
      //   const isAllSelected = needAllOption ? newValue?.some(option => option?.['value'] === null) : false
      //   if (isAllSelected && needAllOption) {
      //     setSelectedValue(formattedDropdownData);
      //     form && form?.setFieldValue(field?.name, formattedDropdownData.map(option => option?.[`${dataStructure?.valueApiParam}`]));
      //     onChange && onChange(formattedDropdownData, setSelectedValue);
      //   } else {
      //     setSelectedValue(newValue!);
      //     form && form?.setFieldValue(field?.name, newValue?.[`${dataStructure?.valueApiParam}`]);
      //     onChange && onChange(newValue, setSelectedValue);
      //   }
      // }}
      onChange={(e, newValue) => {
        const isAllSelected = needAllOption ? newValue?.some((option) => option?.['value'] === null) : false;

        if (isAllSelected && needAllOption) {
          // If "Select All" is selected, set all options as selected
          const allOptions = formattedDropdownData.filter((option) => option?.['value'] !== null); // Exclude "Select All"
          setSelectedValue(allOptions);
          form &&
            form?.setFieldValue(
              field?.name,
              allOptions.map((option) => option?.[`${dataStructure?.valueApiParam}`]),
            );
          onChange && onChange(allOptions, setSelectedValue);
        } else {
          setSelectedValue(newValue!);
          form && form?.setFieldValue(field?.name, newValue?.[`${dataStructure?.valueApiParam}`]);
          onChange && onChange(newValue, setSelectedValue);
        }
      }}
      onInputChange={(e, newValue, reason) => {
        onInputChange && onInputChange(newValue, reason);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.root}
          variant={variant}
          label={
            showRequiredStar ? (
              <>
                <span>{label}</span>
                <span style={{ color: 'red' }}>*</span>
              </>
            ) : (
              label
            )
          }
          placeholder={type === 'search' ? placeholder || 'Enter minimum 3 characters to search' : placeholder}
          error={hasError}
          helperText={errText}
          onChange={
            type === 'search'
              ? (e) => {
                  const searchText = e.target.value;
                  if (searchText.length >= 3) {
                    setSearchValue(searchText);
                  }
                }
              : () => {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {busy ? <CircularProgress thickness={4} size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
export default MUIAutocomplete;
