import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchPlatformBidCharges = async (params = {}) => {
  try {
    let res = await api.fetchPlatformBidCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPlatformOfferCharges = async (params = {}) => {
  try {
    let res = await api.fetchPlatformOfferCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPlatformAuctionCharges = async (params = {}) => {
  try {
    let res = await api.fetchPlatformAuctionCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPlatformCommonCharges = async (params = {}) => {
  try {
    let res = await api.fetchPlatformCommonCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};
