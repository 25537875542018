import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { util } from 'prettier';

export const reqTransform = {
  fetchTransportationCostAgainstDistance(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchCreditAdjustment(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  saveUpdatePriceCalculation(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchPriceCalculations(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchTransportationCostAgainstDistance(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  fetchCreditAdjustment(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  saveUpdatePriceCalculation(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  fetchPriceCalculations(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
};
