import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchTransportationCostAgainstDistance = async (params) => {
  try {
    let res = await api.fetchTransportationCostAgainstDistance(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCreditAdjustment = async (params) => {
  try {
    let res = await api.fetchCreditAdjustment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdatePriceCalculation = async (params) => {
  try {
    let res = await api.saveUpdatePriceCalculation(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPriceCalculations = async (params) => {
  try {
    let res = await api.fetchPriceCalculations(params);
    return res;
  } catch (err) {
    throw err;
  }
};
