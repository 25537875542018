import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import { addDays } from 'date-fns';

const FormikDatePickerField = (props) => {
  // const { fieldName, placeholder, formikBag } = props;
  // const { touched, errors } = formikBag;

  const {
    field,
    form,
    fieldName,
    defaultValue,
    placeholder,
    minDate,
    maxDate,
    disabled = false,
    minTime,
    isClosingOn,
    isShowTime = true,
    isMPA,
    from_date
  } = props;
  const { touched, errors } = form;

  const name = field.name || fieldName;

  const [date, setDate] = useState(props.defaultValue ? new Date(props.defaultValue) : null);
  const [initialDateSet, setInitialDateSet] = useState(false)
  const loc = window.location.href.toLowerCase()
  console.log('check date props====>', field, minDate, isClosingOn, date, from_date, defaultValue);

  // useEffect(() => {
  //   defaultValue ? setDate(new Date(defaultValue)) : setDate(null)
  // },[defaultValue])

  useEffect(() => {
    if(loc.includes("converttobuy=true") && !initialDateSet){
      if (defaultValue && from_date) {
        const startDate = new Date(from_date);
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(23, 59, 0, 0);
        if (!moment(startDate).isSame(date)) {
          setDate(startDate);
          form.setFieldValue(name, startDate);
        }
      }
      setInitialDateSet(true)
    }else{
      defaultValue ? setDate(new Date(defaultValue)) : setDate(null)
    }
  }, [defaultValue]);

  const handleChange = (updatedDate) => {
    // if (onlyWeekday) {
    //   const currentDate = new Date();
    //   const currentHour = currentDate.getHours()
    //   if (currentDate.getDay() === updatedDate.getDay()) {
    //     updatedDate = updatedDate.setHours(currentHour)
    //   } else {
    //     updatedDate = updatedDate.setHours(10)
    //   }
    // }
    setDate(updatedDate);
    form.setFieldValue(name, updatedDate);
    props.setIsPickupDateChanged && props.setIsPickupDateChanged(true);
  };

  const hasError = touched[name] && errors[name];

  const calculateMinTime = (newDate) => {
    let isToday = moment(newDate).isSame(moment(), 'day');
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      return nowAddOneHour;
    }
    return moment(newDate).startOf('day').toDate();
  };

  // const isWeekday = (date) => {
  //   const day = date.getDay();
  //   return day !== 0 && day !== 6;
  // };

  // const filterPassedTime = (time) => {
  //   const hour = time.getHours();
  //   return hour > 9 && hour < 19;
  // };

  useEffect(() => {
    if (isMPA && form?.values?.auction_start_datetime && form?.values?.auction_end_datetime) {
      let startDate = moment(form?.values?.auction_start_datetime);
      let endDate = moment(form?.values?.auction_end_datetime);
      let difference = endDate.diff(startDate, "minutes")
      form.setFieldValue('auction_duration_enum', difference)
    }
  }, [form?.values?.auction_start_datetime, form?.values?.auction_end_datetime])

  return (
    <div className="date-picker">
      <div className={`${hasError ? 'has-error' : ''} bfc-date-time-wrapper w-100"`}>
        {/* <div className="date-picker date-picker--single"> */}
        <DatePicker
          // timeFormat="HH:mm"
          // className="form__form-group-datepicker"
          selected={date}
          selectsStart
          onChange={handleChange}
          showTimeSelect={isShowTime ? true : false}
          dateFormat={isShowTime ? "MMMM d, yyyy  h:mm aa" : "MMMM d, yyyy"}
          dropDownMode="select"
          withPortal={isMobileOnly}
          minDate={isClosingOn ? new Date() : (addDays(minDate, 1) || new Date())}
          maxDate={maxDate}
          minTime={
            moment(date || new Date()).isSame(moment(), 'day')
              ? calculateMinTime(minTime)
              : moment(date).startOf('day').toDate()
          }
          maxTime={moment().endOf('day').toDate()}
          placeholderText={placeholder}
          disabled={disabled}
        // filterDate={onlyWeekday ? isWeekday : null}
        // filterTime={onlyWeekday ? filterPassedTime : null}
        />
        {/* {touched[name] && errors[name] && <span className="form__form-group-error">{errors[name]}</span>} */}
      </div>
    </div>
  );
};

export default FormikDatePickerField;
