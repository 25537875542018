import React, { useEffect, useState } from 'react';
import { Pagination, Button, Popover, Switch, Skeleton } from 'antd';
import { CSVLink } from 'react-csv';
import { truncate } from 'lodash';
import MUIButton from 'common/MUIComponents/Button';
import { useHistory } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';

function AntPagination(props) {
  const {
    total,
    handlePageChange,
    page,
    clearFilters,
    reportData,
    getExportReport,
    getExportReportMultipleInvoices,
    downloadInvoices,
    closeArray,
    rowsPerPage,
    csvLink,
    type,
    isHidden,
    requestData,
    setShowHidden,
    showHidden,
    isDownloadExtract,
    busy,
    setScrollToFirstRow,
    isDownloadingProgress,
    setIsDownloadingProgress,
    isClientSidePagination = false,
    headers,
    needSmallSize
  } = props;

  const path = location.pathname;
  const history = useHistory();
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedDownloadInv, setIsClickedDownloadInv] = useState(false);

  const handleDownloadClick = () => {
    setIsClicked(true)
    setTimeout(() => {
      // Simulate some time delay to represent download completion
      setIsClicked(false);
    }, 5000); // Simulate download duration (5 seconds)
  };

  useEffect(() => {
    if (!_.isEmpty(reportData)) {
      setIsClicked(false);
    }
    if (!_.isEmpty(reportData)) {
      setIsClickedDownloadInv(false);
    }
  }, [reportData]);

  return (
    <div className="d-flex align-items-center" style={{ width: busy ? 'fit-content' : 'auto' }}>
      {busy ? (
        <Skeleton
          className="pagination-skeleton ml-10 mr-10"
          active="true"
          title={true}
          paragraph={false}
          style={{ width: '350px' }}
        />
      ) : isClientSidePagination ? (
        <Pagination
          total={total}
          showTotal={(total, range) => `Total: ${total}`}
          defaultPageSize={10}
          current={page !== undefined ? parseInt(page) : 1}
          position={['bottomLeft']}
          showSizeChanger={true}
          onChange={(page, pageSize) => {
            handlePageChange({ current: page, pageSize: pageSize });
            setScrollToFirstRow && setScrollToFirstRow(true);
          }}
          pageSize={rowsPerPage}
          className="bottom-pagination p-20"
        />
      ) : (
        <Pagination
          total={total}
          showTotal={(total, range) => `Total: ${total}`}
          defaultPageSize={10}
          current={page !== undefined ? parseInt(page) : 1}
          position={['bottomLeft']}
          showLessItems={true}
          // simple={true}
          size={needSmallSize ? 'small' : 'medium'}
          showSizeChanger={true}
          onChange={(page, pageSize) => {
            handlePageChange({ current: page, pageSize: pageSize });
            setScrollToFirstRow && setScrollToFirstRow(true);
          }}
          pageSize={rowsPerPage}
          className="bottom-pagination p-20"
        />
      )}
      {!isDownloadExtract ? (
        <div className="button-section">
          {clearFilters !== undefined && (
            <>
              <Button size={needSmallSize ? 'small' : 'medium'} onClick={() => clearFilters()} className="paganation-btn filter-btn">
                Clear filters
              </Button>
            </>
          )}
          {type !== undefined && (
            <>
              {!total || isClicked ? (
                <Popover content={isClicked ? 'Downloading is in progress' : 'No Data'}>
                  <Button
                    size={needSmallSize ? 'small' : 'medium'}
                    onClick={() => {
                      setIsClicked(true);
                      getExportReport();
                    }}
                    className="paganation-btn report-btn"
                    disabled={!total || isClicked ? true : false}>
                    Download csv
                  </Button>
                </Popover>
              ) : (
                <Button
                  size={needSmallSize ? 'small' : 'medium'}
                  onClick={() => {
                    handleDownloadClick();
                    getExportReport();
                  }}
                  className="paganation-btn report-btn"
                  disabled={!total || isClicked ? true : false}>
                  Download csv
                </Button>
              )}
              {(path.includes('/market/invoices') ||
                path.includes('/network/invoices') ||
                path.includes('/controltower/invoices')) && (
                  <>
                    {_.isEmpty(closeArray) ? (
                      <Button
                        size={needSmallSize ? 'small' : 'medium'}
                        onClick={() => {
                          setIsClickedDownloadInv(true);
                          // getExportReportMultipleInvoices();
                          downloadInvoices();
                        }}
                        className="paganation-btn report-btn"
                        disabled={true}>
                        Download Inv
                      </Button>
                    ) : isClickedDownloadInv ? (
                      // <Popover content={'Downloading is in progress'}>
                      <Button
                        size={needSmallSize ? 'small' : 'medium'}
                        onClick={() => {
                          setIsClickedDownloadInv(true);
                          // getExportReportMultipleInvoices();
                          downloadInvoices();
                        }}
                        className="paganation-btn report-btn"
                        disabled={closeArray.length < 0 || !isDownloadingProgress ? false : true}>
                        Download Inv
                      </Button>
                    ) : (
                      // </Popover>
                      <Button
                        size={needSmallSize ? 'small' : 'medium'}
                        onClick={() => {
                          setIsClickedDownloadInv(true);
                          // getExportReportMultipleInvoices();
                          downloadInvoices();
                        }}
                        className="paganation-btn report-btn"
                        disabled={closeArray.length < 0 || !isDownloadingProgress ? false : true}>
                        Download Inv
                      </Button>
                    )}
                  </>
                )}
              <CSVLink
                data={reportData}
                filename={`${type}-${Date.now()}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
                headers={headers}
              />
            </>
          )}
        </div>
      ) : (
        <MUIButton
          size={needSmallSize ? 'small' : 'medium'}
          variant="outlined"
          onClick={() => history.push('/controltower/data-setup/new-data-extract')}>
          Download Extracts
        </MUIButton>
      )}
      {isHidden ? (
        <div className="d-flex align-items-center ml-20">
          <Switch onClick={() => setShowHidden(!showHidden)} className="paganation-btn report-btn " />
          <span className="ml-10">Show Hidden</span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default AntPagination;
