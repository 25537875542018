import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchAllCashFlowDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllCashFlowDetails-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllCashFlowDetails');
    throw errors;
  }
};

export const setSingleInvoiceDeals = async (params) => {
  try {
    let qsParams = reqTransform.setSingleInvoiceDeals(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/SetSingleInvoiceForDeal?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.setSingleInvoiceDeals(resData);
  } catch (err) {
    console.log('api-SetSingleInvoiceForDeal-setSingleInvoiceDeals->err------>', err);
    const errors = await utils.processApiErrors(err, '', 'SetSingleInvoiceForDeal');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchPreviewForSingleInvoice`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('FetchPreviewForSingleInvoice-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPreviewForSingleInvoice');
    throw errors;
  }
};

export const CreateSingleInvoice = async (params, ids) => {
  try {
    let qsParams = reqTransform.createSingleInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/CreateSingleInvoice?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(ids),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createSingleInvoice(resData);
  } catch (err) {
    console.log('CreateSingleInvoice-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateSingleInvoice');
    throw errors;
  }
};

// export const CreateReceipts = async (params, ids) => {
//   try {
//     let qsParams = reqTransform.createSingleInvoice(params);
//     let bodyParams = reqTransform.create(ids);
//     const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/CreateSingleReceipt?${qsParams}`;
//     const res = await utils.fetch(url, {
//       method: 'POST',
//       body: JSON.stringify(bodyParams),
//     }).ready;
//     const resData = await utils.processApiRes(res);
//     return resTransform.create(resData);
//   } catch (err) {
//     console.log('CreateReceipts-create->err---->', err);
//     const errors = await utils.processApiErrors(err, '', 'CreateReceipts');
//     throw errors;
//   }
// };

export const CreateReceipts = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/CreateSingleReceipt`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('CreateReceipts-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateReceipts');
    throw errors;
  }
};
