import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { rentalService } from 'services';
import history from 'lib/history';
import dayjs from 'dayjs';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'equipmentRental',
  initialState: adapter.getInitialState({
    busy: false,
    reportData: [],
    equipmentLocsData: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setReportData: (state, { payload }) => ({ ...state, reportData: payload }),
    setEquipmentLocsData: (state, { payload }) => ({ ...state, equipmentLocsData: payload }),
  },
});

const getAllEquipments = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.getAllEquipments(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getEquipmentMasterProfile = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.getEquipmentMasterProfile(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const verifyEquipmentProfile = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.verifyEquipmentProfile(params);
    return resData;
    //dispatch(slice.actions.setAll(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getEquipmentRentalPaymentDetails = (params) => async (dispatch) => {
  if (!params?.isCsv) {
    dispatch(slice.actions.setBusy(true));
  }
  try {
    const resData = await rentalService.getEquipmentRentalPaymentDetails(params);
    if (params?.isCsv) {
      const reportData = resData?.map((item) => {
        return {
          'Payment ID': item?.payment_master_id,
          'Deal Id': item?.commodity_deal_master_id,
          Invoice: item?.custom_invoice_number,
          Buyer: item?.paid_by_entity_code,
          Seller: item?.paid_to_entity_code,
          TDS: item?.tds_amount ? `₹${item?.tds_amount}` : '₹0',
          'Total Amount': item?.total_payment_amount ? `₹${item?.total_payment_amount}` : '₹0',
          'Amount Paid': item?.amount_paid ? `₹${item?.amount_paid}` : '₹0',
          'Amount Due': item?.amount_due ? `₹${item?.amount_due}` : '₹0',
          'Paid Date Time': item?.payment_made_date ? dayjs(item?.payment_made_date).format('DD-MMM-YYYY') : '',
        };
      });
      dispatch(slice.actions.setReportData(reportData));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const payEquipmentRentalPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.payEquipmentRentalPayment(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
    dispatch(slice.actions.setBusy(false));
  }
};

const fetchAllRentalTransactions = (params) => async (dispatch) => {
  if (!params?.isCsv) {
    dispatch(slice.actions.setBusy(true));
  }
  try {
    const resData = await rentalService.fetchAllRentalTransactions(params);
    if (params?.isCsv) {
      const reportData = resData?.map((item) => {
        return {
          'Rental Id': item?.commodity_deal_master_id,
          'Equipment Id': item?.equipment_id,
          Category: item?.equipment_category_code,
          Type: item?.equipment_type_code,
          'Maker & Variant': `${item?.equipment_make_code}/${item?.equipment_variant_code}`,
          Location: item?.equipment_address,
          'Order Date': item?.order_date ? dayjs(item?.order_date).format('DD-MMM-YYYY') : '',
          'Start Date': item?.start_date ? dayjs(item?.start_date).format('DD-MMM-YYYY') : '',
          'End Date': item?.end_date ? dayjs(item?.end_date).format('DD-MMM-YYYY') : '',
          Owner: item?.equipment_owner,
          'Hired By': item?.buyer_company_code ? item?.buyer_company_code : item?.buyer_person_code,
          'Rate(Ccy & UOM)': `₹${utils.formatQtyPricePerUOM(item?.trade_price)}/${item?.price_uom_code}`,
          Amount: item?.amount ? `₹${item?.amount}` : '',
          'Payment Terms': item?.payment_term_code,
          ASC: item?.transport_deal_master_id,
          Status: item?.eq_status_code,
          'Linked Deal': item?.linked_deal_id || '',
        };
      });
      dispatch(slice.actions.setReportData(reportData));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updateTransportDealToEquipmentDeal = (id, params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.updateTransportDealToEquipmentDeal(id, params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const GetPrevAddressesEquipment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.GetPrevAddressesEquipment(params);
    const reportData = resData?.map((item) => {
      return {
        label: item?.location_code,
        value: {
          id: item?.place_id,
          ...item,
        },
      };
    });
    dispatch(slice.actions.setEquipmentLocsData(reportData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchSuggestedEquipmentSells = (params, isRefreshed) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));

  try {
    const resData = await rentalService.fetchSuggestedEquipmentSells(params);
    if (isRefreshed) {
      dispatch(slice.actions.setAll(resData));
    } else {
      dispatch(slice.actions.upsertMany(resData));
    }
    console.log('getEquipmentMasterProfile', resData);
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  getAllEquipments,
  getEquipmentMasterProfile,
  verifyEquipmentProfile,
  getEquipmentRentalPaymentDetails,
  payEquipmentRentalPayment,
  fetchAllRentalTransactions,
  updateTransportDealToEquipmentDeal,
  GetPrevAddressesEquipment,
  fetchSuggestedEquipmentSells,
};

const selectors = {
  ...adapter.getSelectors((state) => state.transportDriver),
};

export { actions, selectors };

export default slice.reducer;
