import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Drivers
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    // const date = bodyParams.filters.date;
    // date.setDate(date.getDate() + 1); // Increment date by one day
    // bodyParams.filters.date = date.toISOString().split('T')[0];
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/DieselDispense/FetchFuelDispensed`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

export const saveAndUpdateDispenseApproval = async (params = {}) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/DieselDispense/SaveAndUpdateFuelPlanning`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

export const getFuelDispenseHistoryData = async (params = {}) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/DieselDispense/FetchHistoryFuelPlanning`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

// export const deleteStorageRecordFuel = async (params = {}) => {
//   try {
//     const bodyParams = reqTransform.create(params);
//     const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/DeleteStorageRecordFuel`;
//     const res = await utils.fetch(url, {
//       method: 'PUT',
//       body: JSON.stringify(bodyParams),
//     }).ready;
//     const resData = await utils.processApiRes(res);
//     return resTransform.create(resData);
//   } catch (err) {
//     console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
//     const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
//     throw errors;
//   }
// };

// export const deleteStorageRecordFuel = async (id) => {
//   try {
//     const qs = reqTransform.destroy(id);
//     const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/DeleteStorageRecordFuel?${qs}`;
//     const res = await utils.fetch(url, {
//       method: 'PUT',
//     }).ready;
//     const resData = await utils.processApiRes(res);
//     return resTransform.destroy(resData);
//   } catch (err) {
//     console.log('DeleteDriverForTransporter->err---->', err);
//     const errors = await utils.processApiErrors(err, '', 'DeleteDriverForTransporter');
//     throw errors;
//   }
// };
