import _ from 'lodash';
import dayjs from 'dayjs';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    const bodyparams = {
      ...params,
      filters: params['filters'],
    };

    return bodyparams;
  },
  create(params = {}) {
    const bodyparams = {
      ...params,
      // transporter_person_id: _.get(window, 'user.userid', 0), //526
      // operation_enum: 1,
    };

    return bodyparams;
  },

  update(id, params) {
    const bodyparams = {
      ...params,
      person_id: id,
      transporter_person_id: _.get(window, 'user.userid', 0), //526
      operation_enum: 3,
    };

    return bodyparams;
  },

  destroy(id) {
    const qsParams = {
      record_fuel_id: id,
    };

    return utils.getQs(qsParams);
  },

  fetchDriverDetailsByMobile(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  link(id, params) {
    const qsParams = {
      ...params,
      person_id: id,
      transporter_person_id: _.get(window, 'user.userid', 0), //526
    };

    return utils.getQs(qsParams);
  },
  eKycDocumentVerification(params) {
    let bodyParams = {
      verification_type_enum: 3,
      document_number: params['license_number'],
      document_name: params['first_name'] + ' ' + params['middle_name'] + ' ' + params['last_name'],
      company_affiliate_enum: 0,
      expiry_date: null,
      entity_id: _.get(window, 'user.userid') || 0,
      dob: dayjs(params['dob']).format('YYYY-MM-DD'),
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let driverData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    console.log('Omkar Data--->', driverData);
    // return _.keyBy(driverData, 'carnot_device_master_id');
    return driverData;
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'carnot_device_master_id'),
    };
    return data;
  },
  create(resData) {
    return resData;
  },
  update(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },
  fetchDriverDetailsByMobile(resData) {
    return resData;
  },
  link(resData) {
    return resData;
  },
  eKycVerification(resData) {
    return resData;
  },
};
