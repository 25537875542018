import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import inMemoryJWT from './inMemoryJWT';
import storage from 'lib/storage';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const sendSignupOTP = async (params) => {
  const storeState = globalThis.store.getState();
  try {
    const bodyParams = {
      user_name: '',
      login_through_enum: 2,
      isResend: false,
      access_method_type_enum: 2,
      log_id: null,
      is_requested_number_change: false,
      person_id: 0,
      ...params,
    };

    if (utils.isNumber(bodyParams['user_name'])) {
      bodyParams['login_through_enum'] = 1;
    }
    bodyParams['person_id'] = parseInt(_.get(storeState, 'user.userid')) || 0;
    const url = `${baseUrl}/BioFuelRegistration/api/Registration/SendOTP`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const verifySignupOTP = async (params = {}) => {
  try {
    let storeState = globalThis.store.getState();
    let misc = _.get(storeState, 'misc') || {};

    let bodyParams = {
      user_name: '',
      login_through_enum: 2,
      otp: '',
      log_id: null,
      login_user_id: null,
      access_method_type_enum: 2,
      role_id: 1,
      person_id: null,
      ...params,
      ...misc,
    };

    if (utils.isNumber(bodyParams['user_name'])) {
      bodyParams['login_through_enum'] = 1;
    }

    if (bodyParams['isResend']) {
      bodyParams['log_id'] = null;
    }

    let url = `${baseUrl}/BioFuelRegistration/api/Registration/VerifyOTP`;

    let res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    // await authProvider.checkAuthError(res)
    const resData = await utils.processApiRes(res);

    return resData;
  } catch (err) {
    console.log('authSerice->login-error->err', err);
    const errors = await utils.processApiErrors(err);
    console.log('authSerice->login-error->errors', errors);
    throw errors;
  }
};

export const signup = async (params) => {
  try {
    const bodyParams = {
      first_name: '',
      middle_name: '',
      last_name: '',
      email_id: '',
      mobile_number: '',
      company_id: null,
      company_tagname: '',
      address_type_enum: 3, //TODO : confirm what is this
      address_lattitude: '100',
      address_longitude: '100',
      // user_Type_Enum: 0,
      city_id: 0,
      district_id: 0,
      state_id: 0,
      country_id: 0,
      location_id: 0,
      taluka_id: 0,
      village_id: 0,
      // is_Transaction_Address: true,
      is_registered_as_company: false,
      login_through_enum: 2,
      agent_id: 0,
      is_newsLetter_subscribed: false,
      role_id: 1,
      city_code: ' ',
      ...params,
      is_registered_as_company: false,
      company_id: null,
      company_tagname: '',
      access_method_type_enum: 2,
      pincode: params['pin_code'],
    };

    if (utils.isNumber(bodyParams['user_name'])) {
      bodyParams['login_through_enum'] = 1;
      // bodyParams['login_through_enum_text'] = 'Mobile';
    } else {
      bodyParams['login_through_enum'] = 2;
      // bodyParams['login_through_enum_text'] = 'Email';
    }

    if (!bodyParams['address_text']) {
      bodyParams['address_text'] = bodyParams['location_code'] || '';
    }

    const url = `${baseUrl}/BioFuelRegistration/api/Registration/RegisterUser_Eloc`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resDataRaw = await utils.processApiRes(res);

    const resData = _.get(resDataRaw, 'login_response') || {};

    const token = _.get(resData, 'jwt_token', '');
    const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'social_profile_id');
    await storage.setItem('sid', `${sid}`);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const farmerSignup = async (params) => {
  try {
    const bodyParams = {
      ...params,
    };

    bodyParams['login_through_enum'] = 1;

    if (!bodyParams['address_text']) {
      bodyParams['address_text'] = bodyParams['location_code'] || '';
    }

    const url = `${baseUrl}/BioFuelRegistration/api/Registration/RegisterFarmer`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resDataRaw = await utils.processApiRes(res);

    const resData = _.get(resDataRaw, 'login_response') || {};

    const token = _.get(resData, 'jwt_token', '');
    const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'social_profile_id');
    await storage.setItem('sid', `${sid}`);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const registerUser = async (params) => {
  try {
    const bodyParams = {
      ...params,
    };

    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/WhatsappBot/RegisterUser`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resDataRaw = await utils.processApiRes(res);

    const resData = _.get(resDataRaw, 'login_response') || {};
    if (!bodyParams?.isFacilitatorFarmerOnboarding) {
      const token = _.get(resData, 'jwt_token', '');
      const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

      const rfToken = _.get(resData, 'refresh_token', '');
      await inMemoryJWT.setToken(token, tokenExpiry);

      await inMemoryJWT.setRefreshToken(rfToken);

      //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
      const pid = _.get(resData, 'person_id', '');
      await storage.setItem('pid', `${pid}`);

      //also storing sid since we need to call this after calling refresh token
      const sid = _.get(resData, 'social_profile_id');
      await storage.setItem('sid', `${sid}`);
    }

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    utils.displayErrors(errors);
    throw errors;
  }
};

export const getVPAAgainstMobileNumber = async (mobile_number) => {
  try {
    const params = {
      mobile_number,
    };
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/WhatsappBot/GetVPAAgainstMobileNumber?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    // throw errors;
    return errors;
  }
};

export const verifyMissedCallMobileNumber = async (mobile_number) => {
  try {
    const params = {
      mobile_number,
    };
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/WhatsappBot/VerifyMissedCallMobileNumber?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const updateUserDetailsAgainstMobileNumber = async (params) => {
  try {
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/WhatsappBot/UpdateUserDetailsAgainstMobileNumber`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const sendLoginOTP = async (params) => {
  console.log('check params', params);
  try {
    const bodyParams = {
      user_name: '',
      login_through_enum: 2,
      isResend: false,
      access_method_type_enum: 2,
      log_id: null,
      ...params,
    };

    if (utils.isNumber(bodyParams['user_name'])) {
      bodyParams['login_through_enum'] = 1;
    }

    const url = `${baseUrl}/BioFuelOAuthServer/Users/login`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);

    return resData;
  } catch (err) {
    console.log('authSerice->sendLoginOTP-error->err', err);
    const errors = await utils.processApiErrors(err);
    console.log('authSerice->sendLoginOTP-error->errors', errors);
    throw errors;
  }
};
export const loginQuote = async (params = {}) => {
  try {
    let storeState = globalThis.store.getState();
    // let misc = _.get(storeState, 'misc') || {};
    let misc = localStorage.getItem('quoteVerification') || {};
    misc = JSON.parse(misc);
    let isMobile = _.get(params, 'isMobile');
    let isIPad = _.get(params, 'isIPad');
    // let isLaptop = _.get(params, 'isLaptop');
    let setEnum;
    if (isMobile) {
      setEnum = 3;
    } else if (isIPad) {
      setEnum = 4;
    } else {
      setEnum = 2;
    }

    let bodyParams = {
      user_name: '',
      login_through_enum: 2,
      otp: '',
      log_id: null,
      login_user_id: null,
      access_method_type_enum: setEnum,
      role_id: 1,
      person_id: null,
      ...params,
      ...misc,
    };

    if (utils.isNumber(bodyParams['user_name'])) {
      bodyParams['login_through_enum'] = 1;
    }

    if (bodyParams['isResend']) {
      bodyParams['log_id'] = null;
    }

    let url = `${baseUrl}/BioFuelOAuthServer/Users/verify-login`;

    let res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);

    const token = _.get(resData, 'jwt_token', '');
    const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'social_profile_id');
    await storage.setItem('sid', `${sid}`);

    const is_indent_enabled = _.get(resData, 'is_indent_enabled') || false;
    await storage.setItem('is_indent_enabled', `${is_indent_enabled}`);

    const is_seller_indent_enabled = _.get(resData, 'is_seller_indent_enabled') || false;
    await storage.setItem('is_seller_indent_enabled', `${is_seller_indent_enabled}`);

    return resData;
  } catch (err) {
    console.log('authSerice->login-error->err', err);
    const errors = await utils.processApiErrors(err);
    console.log('authSerice->login-error->errors', errors);
    throw errors;
  }
};
export const login = async (params = {}) => {
  try {
    let storeState = globalThis.store.getState();
    let misc = _.get(storeState, 'misc') || {};
    let isMobile = _.get(params, 'isMobile');
    let isIPad = _.get(params, 'isIPad');
    // let isLaptop = _.get(params, 'isLaptop');

    let setEnum;
    if (isMobile) {
      setEnum = 3;
    } else if (isIPad) {
      setEnum = 4;
    } else {
      setEnum = 2;
    }

    let bodyParams = {
      user_name: '',
      login_through_enum: 2,
      otp: '',
      log_id: null,
      login_user_id: null,
      access_method_type_enum: setEnum,
      role_id: 1,
      person_id: null,
      ...params,
      ...misc,
    };

    if (utils.isNumber(bodyParams['user_name'])) {
      bodyParams['login_through_enum'] = 1;
    }

    if (bodyParams['isResend']) {
      bodyParams['log_id'] = null;
    }

    let url = `${baseUrl}/BioFuelOAuthServer/Users/verify-login`;

    let res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    // await authProvider.checkAuthError(res)
    const resData = await utils.processApiRes(res);

    const token = _.get(resData, 'jwt_token', '');
    const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'social_profile_id');
    await storage.setItem('sid', `${sid}`);

    const is_indent_enabled = _.get(resData, 'is_indent_enabled') || false;
    await storage.setItem('is_indent_enabled', `${is_indent_enabled}`);

    const is_seller_indent_enabled = _.get(resData, 'is_seller_indent_enabled') || false;
    await storage.setItem('is_seller_indent_enabled', `${is_seller_indent_enabled}`);

    const loginStatusId = _.get(resData, 'login_status_id', '');
    await storage.setItem('login_status_id', `${loginStatusId}`);

    return resData;
  } catch (err) {
    console.log('authSerice->login-error->err', err);
    const errors = await utils.processApiErrors(err);
    console.log('authSerice->login-error->errors', errors);
    throw errors;
  }
};

export const logout = async (params) => {
  try {
    inMemoryJWT.ereaseToken();
    window.location.reload(false);
    return true;
  } catch (err) {
    console.log('authSerice->logout-error->err', err);
    const errors = await utils.processApiErrors(err);
    console.log('authSerice->logout-error->errors', errors);
    throw errors;
  }
};

export const verifyBankAccount = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/VerifyBankAccount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const SaveUpdatePersonalDetails = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/Facilitator/SaveUpdatePersonalDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};
