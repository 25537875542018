import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
// import { LoadScript } from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// import Router from './Router/index';
// import store from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from '../../redux/store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import Loading from '../../shared/components/Loading';
import BFCAuthProvider from './BFCAuthProvider';
import BFCRedirect from './BFCRedirect';

import history from 'lib/history';
import AppRoutes from './Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import disableDevtool from 'disable-devtool';
import ReactGA from 'react-ga';


// import AppRoutes from 'app/Router'; // @TODO: switch to lazy loading
import IndentDealsRoutes from './RouterIndent';

// please import scss or css files here
import 'theme/scss/app.scss';
import 'assets/scss/bfc.scss';
import MUIButton from 'common/MUIComponents/Button';
import ReplyIcon from '@material-ui/icons/Reply';
import { IconButton } from '@material-ui/core';
import { Tooltip } from 'antd';
import config from 'config';
import { getDecryptedUrl } from 'lib/utils';

const persistor = persistStore(store);
// if (window.location.href.includes('https://my.biofuelcircle.com') || window.location.href.includes('https://test.biofuelcircle.com')) {
//   disableDevtool();
// }

i18n.init(i18nextConfig);
// 127.0.0.1:3000/
const TRACKING_ID = "UA-238216085-3"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// ReactGA.ga('create', TRACKING_ID, 'auto', {userId: '12345'})
// ReactGA.ga('set', 'userId', 'USER_ID'); // Set the user ID using signed-in user_id.
ReactGA.pageview(window.location.pathname + window.location.search);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
    //   typography: {
    //   // fontFamily: [
    //   //   // 'Raleway'
    //   // ].join(','),
    // },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

ThemeComponent.defaultProps = {
  themeName: 'light',
};

const ConnectedThemeComponent = connect((state) => ({ themeName: state.theme.className }))(ThemeComponent);


class App extends Component {

  constructor () {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
    // firebase.initializeApp(firebaseConfig);
    // ReactGA.initialize('UA-000000-01');
    // ReactGA.initialize('UA-238216085-2');
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { loaded, loading } = this.state;

    const loc = getDecryptedUrl(window.location.href);
    const { indentDealsUrl ,iProfitUrl } = config;

    const authenticateIProfitUser = async () => {
      const iProfitData = window?.user?.profile?.sso_config;
      const params = {
        User_ID: iProfitData?.iprofit_user_id,
        Organization_Id: iProfitData?.iprofit_company_id,
        AccessToken: iProfitData?.iprofit_session_detail?.iprofit_refresh_token,
      };
      const res = await fetch(
        `${iProfitUrl}/api/iprofitThirdPartyIntegration/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      const success = await res?.json();
      if (success?.responseData?.refresh_token) {
        localStorage.clear();
        window.location.href = `${success?.responseData?.return_callback_url}/${success?.responseData?.refresh_token}`;
      }
    };

    return (
      <Provider store={store}>
        <PersistGate loading={<Loading loading />} persistor={persistor}>
          <BFCAuthProvider>
            <Router basename="/" history={history}>
              <BFCRedirect />
              <I18nextProvider i18n={i18n}>
                <ScrollToTop>
                  <>
                    {!loaded && <Loading loading={loading} />}
                    <ThemeComponent>
                      <div>
                        {loc.includes(indentDealsUrl) ? <IndentDealsRoutes /> : <AppRoutes />}
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="colored"
                          bodyStyle={{ fontFamily: 'Segoe UI', fontWeight: '600', wordBreak: 'break-word' }}
                        />
                      </div>
                    </ThemeComponent>
                    {window?.user?.profile?.sso_config?.traversed_from_enum == 1 ?
                      <MUIButton
                        size='small'
                        tooltipText={`Back To ${window?.user?.profile?.sso_config?.traversed_from_enum_code}`}
                        startIcon={<ReplyIcon />}
                        style={{
                          position: 'fixed',
                          right: 20,
                          bottom: 10,
                          zIndex: 1000
                        }}
                        color='#1b62ab'
                        onClick={async () => {
                          authenticateIProfitUser();
                        }}>Back To {window?.user?.profile?.sso_config?.traversed_from_enum_code}</MUIButton> : ''}
                    {/* {window?.user?.profile?.sso_config?.traversed_from_enum == 1 ?
                      <Tooltip placement='top' title={`Back To ${window?.user?.profile?.sso_config?.traversed_from_enum_code}`}>
                        <IconButton size='small' style={{
                          background: '#1b62ab',
                          position: 'fixed',
                          right: 15,
                          bottom: 5,
                          zIndex: 1000,
                          color: '#fff',
                          padding: '8px'
                        }} onClick={async () => {
                          authenticateIProfitUser();
                          // window.location.href = window?.user?.profile?.sso_config?.iprofit_session_detail?.return_callback_url;
                        }}>
                          <ReplyIcon />
                        </IconButton>
                      </Tooltip> : ''} */}
                  </>
                </ScrollToTop>
              </I18nextProvider>
            </Router>
          </BFCAuthProvider>
        </PersistGate>
      </Provider>
    );
  }
}

/*
  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>

          <BrowserRouter basename="/">
          <BFCAuthProvider >
            <I18nextProvider i18n={i18n}>
              <LoadScript
                googleMapsApiKey=""
                loadingElement={(<Loading loading={loading} />)}
              >
                <ScrollToTop>
                  <Fragment>
                    {!loaded
                        && (
                          <Loading loading={loading} />
                        )
                      }
                    <ConnectedThemeComponent>
                      <div>
                        <Router />
                      </div>
                    </ConnectedThemeComponent>
                  </Fragment>
                </ScrollToTop>
              </LoadScript>
            </I18nextProvider>
            </BFCAuthProvider>
          </BrowserRouter>

      </Provider>
    );
  }
*/

export default App;
