import _, { values } from 'lodash';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import utils from 'lib/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormikMaterialTextFieldAcct from './FormikMaterialTextFieldAcct';
import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import { actions as companyActions } from 'redux/companies';
import BankSelect from 'containers/Account/Profile/KYCBusiness/components/bankSelect';
import * as handlers from '../handlers';
import * as businessHandlers from 'containers/Account/Profile/KYCBusiness/handlers';
import ToggleSwitch from './toggleSwitch';
import MUITextField from 'common/MUIComponents/TextField';
import MUITypography from 'common/MUIComponents/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useQuery  from 'lib/hooks/useQuery';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Space } from 'antd';
import NumpadInput from 'common/NumpadInput';

const bankFields = {
  account_name: '',
  account_number: '',
  re_account_number: '',
  ifsc_code: '',
  bank_account_id: 0,
  operation_enum: 1,
  is_default: true,
  is_active: true,
  bank_name: '',
  is_savings_account: false,
  vpa_id: ''
};
const bankSchema = yup.object().shape({
  account_name: yup.string().required('Beneficiary Name is required').min(4, 'Minimum 4 characters are required'),
  ifsc_code: yup.string().required('IFSC code is required'),
  // bank_name: yup.string().required('Bank name is required'),
  account_number: yup
    .string()
    .matches(/^[0-9]+$/, 'Only numeric values allowed')
    .required('Account Number is required'),
  re_account_number: yup
    .string()
    .oneOf([yup.ref('account_number'), ''], 'Does not match with Acoount Number!')
    .required('Required'),
});
const businessBankSchema = yup.object().shape({
  ifsc_code: yup.string().required('IFSC code is required'),
  // bank_name: yup.string().required('Bank name is required'),
  account_number: yup
    .string()
    .matches(/^[0-9]+$/, 'Only numeric values allowed')
    .required('Account Number is required'),
  re_account_number: yup
    .string()
    .oneOf([yup.ref('account_number'), ''], 'Does not match with Acoount Number!')
    .required('Required'),
  account_name: yup.string().when('is_savings_account', {
    is: true,
    then: yup.string().min(4, 'Minimum 4 characters are required'),
    otherwise: yup.string().optional(),
  }),
});

const BankPopup = (props) => {
  const dispatch = useDispatch();
  const {id} = useQuery()
  const user = useSelector((state) => state.user);
  const kycUser = useSelector((state) => state.kycUser);
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  const typeOfIndividualEnum = _.get(user, 'profile.person.type_of_individual_enum');
  const isProprietor = _.get(user, 'profile.person.is_proprietor');
  const [accountType, setAccountType] = useState('Saving');
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);  const { isAdmin, kycStatusKey, type, setTimestamp, company_affiliate_enum, entity_id, newBankDetails, setBankDetails, isFacilitator, setBankRow, name } = props;
  const [ifscText, setIFSCText] = useState('Check IFSC');
  const [ifscCode, setIFSCCode] = useState('');
  const [ifscCodeNew, setIFSCCodeNew] = useState('');
  const [ifscbankDetails, setIFSCBankDetails] = useState('');
  const [ifscVerified, setIfscVerification] = useState(false);
  const [switchValue, setSwitchValue] = useState(true);
  const [activeValue, setActiveValue] = useState(true);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [showReAccountNumber, setShowReAccountNumber] = useState(false);
  const bankData = isFacilitator ? newBankDetails?.[0] : _.get(props, 'bankData') || {};
  const bankDetails = _.get(props, 'bankDetails') || {}; //Array
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState('');

  const companies = useSelector((state) => state.companies);
  const companyId = _.get(user, 'profile.person.company_id') || 0;
  const companyData = _.get(companies, `entities[${companyId}]`) || {};

  const initialValues = {
    ...bankFields,
    ...bankData,
    account_name: name,
    vpa_id: null,
  };
  // console.log('isAdmin', CT_parent && user.userid !== parseInt(id),CT_parent)
  const handleCheck = async (values, formikBag) => {
    if (values.eKyc == 'IFSC') {
      const code = values['ifsc_code'];
      let params = {
        ifsc: code
      }
      const success = await handlers.handleverifyIFSC(params, formikBag);
      if (success) {
        const name = success.BANK;
        values['bank_name'] = success.BANK;
        const branch = success.BRANCH;
        const msg = `Bank: ${name}, Branch name: ${branch}`;
        setIFSCBankDetails(msg);
        setIFSCText('Verified');
        setIFSCCodeNew(success.IFSC);
        setIfscVerification(true);
      } else {
        const msg = `Not Found`;
        setIFSCBankDetails(msg);
        setIFSCText('Check IFSC');
        setIfscVerification(false);
      }
    }
  };

  const handleSave = async (values, formikBag) => {
        const saveBankSchema = type === 'business' && !isProprietor ? businessBankSchema : bankSchema;
    // console.log('handleSave values', values, saveBankSchema);
    if (values.bank_account_id !== 0) {
      values['operation_enum'] = 3;
      let params = {
        bank_account_details_request_model: [
          {
            ...values,
          },
        ],
        user_type_enum : CT_parent && user.userid !== parseInt(id) ? _.get(kycUser,'profile.person.user_type_enum') : 0
      };
      if (type == 'business') {
        if (isProprietor) {
          if (values['is_savings_account'] == false) {
            params = {
              bank_account_details_request_model: [
                {
                  ...values,
                  account_name: values['account_name'] || _.get(companyData, 'company_code') || '',
                },
              ],
            };
          } else {
            params = {
              bank_account_details_request_model: [
                {
                  ...values,
                },
              ],
            };
          }
        } else {
          params = {
            bank_account_details_request_model: [
              {
                ...values,
                account_name: values['account_name'] || _.get(companyData, 'company_code') || '',
              },
            ],
          };
        }
      }
      if (type == 'business') {
        params = {
          ...params,
          entity_id: entity_id,
          company_affiliate_enum: company_affiliate_enum,
        };
        const success = await businessHandlers.handleBankUpdate(params, formikBag, setTimestamp, saveBankSchema);
        if (success) {
          props.onSave(values);
        }
      } else {
        const success = await handlers.handleBankUpdate(params, formikBag, setTimestamp, saveBankSchema);
        if (success) {
          props.onSave(values);
        }
      }
      // props.onSave(values);
    } else {
      let params = {
        bank_account_details_request_model: [
          {
            ...values,
          },
        ],
      };
      if (type == 'business') {
        if (isProprietor) {
          if (values['is_savings_account'] == false) {
            params = {
              bank_account_details_request_model: [
                {
                  ...values,
                  account_name: values['account_name'] || _.get(companyData, 'company_code') || '',
                },
              ],
            };
          } else {
            params = {
              bank_account_details_request_model: [
                {
                  ...values,
                },
              ],
            };
          }
        } else {
          params = {
            bank_account_details_request_model: [
              {
                ...values,
                account_name: values['account_name'] || _.get(companyData, 'company_code') || '',
              },
            ],
          };
        }
      }
      if (type == 'business') {
        params = {
          ...params,
          entity_id: entity_id,
          company_affiliate_enum: company_affiliate_enum,
          user_id: _.get(window, 'user.userid') || 0,
        };
        const success = await businessHandlers.handleNewBankCreate(params, formikBag, setTimestamp, saveBankSchema);
        if (success) {
          const bankArray = _.get(success, 'bank_account_details_request_model') || [];
          const bankRecord = bankArray[0];
          values['bank_verification_enum'] = 1;
          values['bank_verification_code'] = 'Unverified';
          values['bank_account_id'] = bankRecord.bank_account_id;
          props.onSave(values);
        }
      } else {
        const success = await handlers.handleBankCreate(params, formikBag, setTimestamp, saveBankSchema);
        if (success) {
          const bankArray = _.get(success, 'bank_account_details_request_model') || [];
          const bankRecord = bankArray[0];
          values['bank_verification_enum'] = 1;
          values['bank_verification_code'] = 'Unverified';
          values['bank_account_id'] = bankRecord.bank_account_id;
          props.onSave(values);
        }
      }
    }
  };

  useEffect(() => {
    const code = _.get(bankData, 'ifsc_code') || '';
    if (code !== '') {
      setIfscVerification(true);
      setIFSCCode(code);
      setIFSCText('Verified');
    }
    const isDefault = _.get(bankData, 'is_default') || false;
    const isActive = _.get(bankData, 'is_active') || false;

    setSwitchValue(isDefault);
    setActiveValue(isActive);
    const accountType = _.get(bankData, 'is_savings_account') || false;
    if (accountType) {
      setAccountType('Saving');
    } else {
      setAccountType('Current');
    }
  }, [bankData]);

  useEffect(() => {
    if (_.isEmpty(bankData)) {
      if (isFacilitator) {
        setAccountType('Saving');
      } else if (typeOfIndividualEnum == 1) {
        setAccountType('Saving');
      } else if (typeOfIndividualEnum == 2) {
        isProprietor ? setAccountType('Current') : setAccountType('Saving');
      } else {
        setAccountType('Current');
      }
    }
  }, []);

  useEffect(() => {
    if (type == 'business' && isProprietor) {
      const params = {
        person_id: _.get(user, 'userid') || 0,
      };
      dispatch(companyActions.fetchList(params));
    }
  }, []);

  const handleReAccountNumberChange = (e, values, setFieldValue) => {
    const reAccountNumber = e.target.value;
    setFieldValue('re_account_number', reAccountNumber);

    // Check if the re-entered account number matches the original
    if (reAccountNumber !== values?.account_number) {
      setError('Does not match with Account Number!');
    } else {
      setError('');
    }
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={type == 'business' && !isProprietor ? businessBankSchema : bankSchema}
        onSubmit={async (values, formikBag) => {
          handleCheck(values, formikBag);
        }}>
        {(formikBag) => {
          // console.log('CommodityReqOfferQualitySpecSchema errors ======== ', formikBag.errors);
          let { setFieldValue, values } = formikBag;
          console.log("Omkar Values", values)
          if (!_.isEmpty(bankData)) {
            if (bankData.ifsc_code !== formikBag.values['ifsc_code']) {
              setIFSCText('Check IFSC');
              if (ifscCodeNew === formikBag.values['ifsc_code']) {
                setIFSCText('Verified');
              }
            } else {
              setIFSCText('Verified');
              setIfscVerification(true);
            }
          }
          let ifscCode = formikBag.values['ifsc_code'];
          formikBag.values['ifsc_code'] = ifscCode.toUpperCase();
          return (
            <Form className="formik-form">
              {!isFacilitator && (type !== 'business' || isProprietor || props.business_type_enum == 1) && (
                <div className="row form__form-group">
                  <div className="col">
                    <div className="radiobox-address">
                      <div className="radiobox-wrapper">
                        <label className="radio-color-btn">
                          Saving{' '}
                          <input
                            type="radio"
                            name="radiocust"
                            value={'Saving'}
                            disabled={isAdmin || kycStatusKey}
                            checked={accountType == 'Saving' ? true : false}
                            onChange={(e) => {
                              setAccountType('Saving');
                              formikBag.setFieldValue('is_savings_account', true);
                            }}
                          />
                          <div className="control-indicator"></div>
                        </label>
                        {typeOfIndividualEnum !== 1 && (
                          <label className="radio-color-btn">
                            Current{' '}
                            <input
                              type="radio"
                              name="radiocust"
                              value={'Current'}
                              checked={accountType == 'Current' ? true : false}
                              disabled={isAdmin || kycStatusKey}
                              onChange={(e) => {
                                setAccountType('Current');
                                formikBag.setFieldValue('is_savings_account', false);
                              }}
                            />
                            <div className="control-indicator"></div>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row form__form-group">
                <div className="col">
                  <span className="form-group-label">Account Number</span>
                  <span class="required">*</span>
                  <div className="form-group-field">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Field
                      name={`account_number`}
                      // component={FormikMaterialTextFieldAcct}
                      disabled={isAdmin || kycStatusKey}
                      fieldType="plainNumeric"
                      component={MUITextField}
                      value={values?.account_number}
                      isPassword={showAccountNumber ? false : true}
                      EndIcon={showAccountNumber ? Visibility : VisibilityOff}
                      endIconBtnOnClick={(e) => setShowAccountNumber(!showAccountNumber)}
                      // type={'password'}
                      placeholder={
                        type !== 'business' && isProprietor && typeOfIndividualEnum == 2
                          ? 'Current bank account'
                          : accountType == 'Saving'
                            ? 'Saving bank account'
                            : 'Current bank account'
                      }
                    /> */}
                    <Input.Password
                      type='number'
                      disabled={isAdmin || kycStatusKey}
                      placeholder={
                        type !== 'business' && isProprietor && typeOfIndividualEnum === 2
                          ? 'Current bank account'
                          : accountType === 'Saving'
                            ? 'Saving bank account'
                            : 'Current bank account'
                      }
                      onChange={(e) => setFieldValue('account_number', e.target.value)}
                      size="large"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      value={values?.account_number}
                    />
                    <NumpadInput defaultValue={values?.account_number} setFieldValue={setFieldValue} inputFieldName={'account_number'} placeholderValue={'Account Number'}  />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-12">
                  <span className="form-group-label">Re-enter Account Number</span>
                  <span class="required">*</span>
                  <div className="form-group-field">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <Field
                        name={`re_account_number`}
                        // component={FormikMaterialTextField}
                        fieldType="plainNumeric"
                        component={MUITextField}
                        value={values?.re_account_number}
                        isPassword={showReAccountNumber ? false : true}
                        EndIcon={showReAccountNumber ? Visibility : VisibilityOff}
                        endIconBtnOnClick={(e) => setShowReAccountNumber(!showReAccountNumber)}
                        disabled={isAdmin || kycStatusKey}
                      /> */}
                      <Input.Password
                        type='number'
                        disabled={isAdmin || kycStatusKey}
                        onChange={(e) => handleReAccountNumberChange(e, values, setFieldValue)}
                        size="large"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        value={values?.re_account_number}
                      />
                      <NumpadInput defaultValue={values?.re_account_number} setFieldValue={setFieldValue} inputFieldName={'re_account_number'} placeholderValue={'Re-enter Account Number'}  />
                      {error && <span className="error-message" style={{ color: 'red' }}>{error}</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-12">
                  <span className="form-group-label">Branch IFSC Code</span>
                  <span class="required">*</span>
                  <div className="form-group-field">
                    <div className="row no-gutters">
                      <MUITextField
                        fieldType="alphanumeric"
                        value={values?.ifsc_code}
                        onChange={(newValue) => {
                          setFieldValue('ifsc_code', newValue);
                          if (newValue === formikBag.values['ifsc_code']) {
                            setIFSCText('Verified');
                          } else {
                            setIFSCText('Check IFSC');
                          }
                        }}
                        uomType="button"
                        endIconBtnDisabled={!values?.ifsc_code || ifscText === 'Verified'}
                        endIconBtnOnClick={async () => {
                          formikBag.values.eKyc = 'IFSC';
                          formikBag.handleSubmit();
                        }}
                        EndIcon={ifscText}
                      />
                      {/* <div className="col-sm-10 col-md-10 col-lg-10">
                        <Field
                          name="ifsc_code"
                          component={FormikMaterialTextField}
                          disabled={isAdmin || kycStatusKey}
                        />
                      </div>
                      <div className="col-sm-2 col-md-2">
                        {formikBag.values.ifsc_code && (
                          <div className="">
                            <div className="form-group-field">
                              <button
                                type="button"
                                style={{
                                  minWidth: 'fit-content',
                                  width: '100%',
                                  height: '38px',
                                  borderTopRightRadius: '5px',
                                  borderBottomRightRadius: '5px',
                                }}
                                className={`form__form-group-button ${ifscText === 'Verified' ? '' : 'active'}`}
                                disabled={ifscText === 'Verified' || isAdmin || kycStatusKey ? true : false}
                                onClick={() => {
                                  formikBag.values.eKyc = 'IFSC';
                                  formikBag.handleSubmit();
                                }}>
                                {ifscText}
                              </button>
                            </div>
                          </div>
                        )}
                      </div> */}
                    </div>
                    {ifscbankDetails !== '' && (
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <div className="notes-block">
                              <p className="greenbg" style={{ color: 'var(--unnamed-color-afafaf)', marginTop: '10px' }}>
                                {ifscbankDetails}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {(type !== 'business' ||
                (isProprietor && typeOfIndividualEnum == 2) ||
                (props.business_type_enum == 1 && formikBag.values['is_savings_account'] == true)) && (
                  <div className="row form__form-group">
                    <div className="col">
                      <span className="form-group-label">Beneficiary Name</span>
                      {type !== 'business' && <span class="required">*</span>}
                      <div className="form-group-field">
                        <Field
                          name={`account_name`}
                          // component={FormikMaterialTextField}
                          type='passw'
                          fieldType="alphabetic"
                          component={MUITextField}
                          value={values?.account_name}
                          disabled={isAdmin || kycStatusKey}
                          placeholder={'Must exactly match your Bank records'}
                        />
                        <MUITypography variant='caption'>Must exactly match with name on bank passbook</MUITypography>
                      </div>
                    </div>
                  </div>
                )}
              {isFacilitator ? '' : <div className="row form__form-group">
                <div className="col">
                  <FormControlLabel
                    control={
                      <ToggleSwitch
                        onChange={(e) => {
                          setSwitchValue(!switchValue);
                          formikBag.values['is_default'] = !switchValue;
                        }}
                        checked={true}
                        name={`is_default`}
                      />
                    }
                    label="This is my main account"
                    labelPlacement="start"
                    style={{ marginLeft: '0px' }}
                  />
                </div>
              </div>}
              {isFacilitator ? '' : <div className="row form__form-group">
                <div className="col">
                  <FormControlLabel
                    control={
                      <ToggleSwitch
                        onChange={(e) => {
                          setActiveValue(!activeValue);
                          formikBag.values['is_active'] = !activeValue;
                        }}
                        checked={true}
                        name={`is_active`}
                      />
                    }
                    label="This is my primary account"
                    labelPlacement="start"
                    style={{ marginLeft: '0px' }}
                  />
                </div>
              </div>}
              <div className="bfc-center">
                <Button
                  color="primary"
                  type="submit"
                  size="sm"
                  disabled={ifscVerified && ifscText === 'Verified' ? false : true}
                  onClick={() => {
                    if (isFacilitator) {
                      const exists = newBankDetails?.findIndex(item => item.time_stamp === formikBag?.values?.time_stamp)
                      if (exists !== -1) {
                        const updated = newBankDetails?.filter((item, index) => index !== exists)
                        updated.push({ ...formikBag.values })
                        setBankDetails(updated)
                        props.onSave(updated);
                      } else {
                        setBankDetails([...newBankDetails, { ...formikBag.values, time_stamp: new Date().getTime() }])
                        props.onSave([...newBankDetails, { ...formikBag.values, time_stamp: new Date().getTime() }]);
                      }
                      setBankRow(-1)
                    } else {
                      formikBag.values.eKyc = '';
                      handleSave(formikBag.values, formikBag);
                    }
                  }}>
                  {!_.isEmpty(bankData) ? 'Update' : 'Save'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default BankPopup;
