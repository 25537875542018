import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchTransportationCostAgainstDistance = async (params) => {
  try {
    const bodyParams = reqTransform.fetchTransportationCostAgainstDistance(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PriceCalculator/FetchTransportationCostAgainstDistance`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTransportationCostAgainstDistance(resData);
  } catch (err: any) {
    console.error('API ERROR - FetchTransportationCostAgainstDistance', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTransportationCostAgainstDistance');
    throw errors;
  }
};

export const fetchCreditAdjustment = async (params) => {
  try {
    const bodyParams = reqTransform.fetchCreditAdjustment(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PriceCalculator/FetchCreditAdjustment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCreditAdjustment(resData);
  } catch (err: any) {
    console.error('API ERROR - FetchCreditAdjustment', err);
    const errors = await utils.processApiErrors(err, '', 'FetchCreditAdjustment');
    throw errors;
  }
};

export const saveUpdatePriceCalculation = async (params) => {
  try {
    const bodyParams = reqTransform.saveUpdatePriceCalculation(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PriceCalculator/SaveUpdatePriceCalculation`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.saveUpdatePriceCalculation(resData);
  } catch (err: any) {
    console.error('API ERROR - SaveUpdatePriceCalculation', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePriceCalculation');
    throw errors;
  }
};

export const fetchPriceCalculations = async (params) => {
  try {
    const bodyParams = reqTransform.fetchPriceCalculations(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PriceCalculator/FetchPriceCalculations`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
    // return resTransform.fetchPriceCalculations(resData);
  } catch (err: any) {
    console.error('API ERROR - fetchPriceCalculations', err);
    const errors = await utils.processApiErrors(err, '', 'fetchPriceCalculations');
    throw errors;
  }
};
