import _ from 'lodash';
import notifications from 'lib/notifications';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import config from 'config';
import { handleDeLinkTelemetryId, handleMapTelemetryId } from 'services/mapTracker/api';
const { baseUrl } = config;

export const getEquipmentDataUsingEquId = async (values) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Fuel Details...',
      key: 'req-form',
    });

    const res =
      await `${baseUrl}/BioFuelThirdpartyIntegration/api/CarNot/GetTrackerEquipmentData?equipmentId=${values}`;
    notifications.show({
      type: 'success',
      message: 'Fuel Details saved.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err);
  }
};

export const MapTelemetryId = async (values) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Trying to map Telemetry ID...',
      key: 'req-form',
    });

    const res = await handleMapTelemetryId(values);
    notifications.show({
      type: 'success',
      message: 'Telemetry ID mapped successfully.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err);
  }
};

// export const MapTelemetryId = async (bodyParams) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Trying to map Telemetry ID...',
//       key: 'req-form',
//     });

//     const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/CarNot/MapTelemetryId`;

//     const res = await utils.fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(bodyParams),
//     }).ready;

//     if (!res.ok) {
//       const errorData = await res.json();
//       console.log(errorData, 'errorData nikhil');

//       // Extract error details dynamically
//       let errorDetails = 'Unknown error';
//       if (errorData.data) {
//         errorDetails = Object.entries(errorData.data)
//           .map(([key, value]) => `${key}: ${value}`)
//           .join(', ');
//       }

//       notifications.show({
//         type: 'error',
//         message: `${errorDetails}`,
//         key: 'req-form',
//       });
//       throw new Error(errorDetails);
//     }

//     const resData = await utils.processApiRes(res);

//     notifications.show({
//       type: 'success',
//       message: 'Telemetry ID mapped successfully.',
//       key: 'req-form',
//     });

//     return resData;
//   } catch (err) {
//     const errors = await utils.processApiErrors(err);
//     throw errors;
//   }
// };

export const DeLinkTelemetryId = async (values) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Trying to De-link Telemetry ID...',
      key: 'req-form',
    });

    const res = await handleDeLinkTelemetryId(values);
    notifications.show({
      type: 'success',
      message: 'Telemetry ID delinked successfully.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err);
  }
};
// export const DeLinkTelemetryId = async (bodyParams) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Trying to De-link Telemetry ID...',
//       key: 'req-form',
//     });

//     const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/CarNot/DeLinkTelemetryId`;

//     const res = await utils.fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(bodyParams),
//     }).ready;

//     if (!res.ok) {
//       const errorData = await res.json();
//       console.log(errorData, 'errorData nikhil');

//       // Extract error details dynamically
//       let errorDetails = 'Unknown error';
//       if (errorData.data) {
//         errorDetails = Object.entries(errorData.data)
//           .map(([key, value]) => `${key}: ${value}`)
//           .join(', ');
//       }

//       throw new Error(errorDetails);
//     }

//     notifications.show({
//       type: 'success',
//       message: 'Telemetry ID delinked successfully.',
//       key: 'req-form',
//     });

//     // return resData;
//   } catch (err) {
//     console.log('handleCreate--->error---->', err);
//     utils.displayErrors(err);
//   }
// };

// export const handleDelete = async (id) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Deleting Fuel Details...',
//       key: 'req-form',
//     });

//     const res = await deleteStorageRecordFuel(id);
//     notifications.show({
//       type: 'success',
//       message: 'Fuel Details deleted.',
//       key: 'req-form',
//     });

//     return res;
//   } catch (err) {
//     console.log('handleCreate--->error---->', err);
//     utils.displayErrors(err);
//   }
// };

// export const handelUpdate = async (values) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Updating Fuel Details...',
//       key: 'req-form',
//     });

//     const res = await saveAndUpdateStorageRecordFuel(values);
//     notifications.show({
//       type: 'success',
//       message: 'Fuel Details updated.',
//       key: 'req-form',
//     });

//     return res;
//   } catch (err) {
//     console.log('handleCreate--->error---->', err);
//     utils.displayErrors(err);
//   }
// };

// export const handleKycUpdate = async (values, formikBag) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Updating Driver Details...',
//       key: 'req-form',
//     });
//     const id = _.get(values, 'person_id') || 0;
//     const res = await driverService.update(id, values);
//     notifications.show({
//       type: 'success',
//       message: 'Driver details updated.',
//       key: 'req-form',
//     });

//     return res;
//   } catch (err) {
//     console.log('handleUpdate--->error---->', err);
//     utils.displayErrors(err, formikBag);
//   }
// };

// export async function handleDestroy(id = 0) {
//   try {
//     let res = await driverService.destroy(id);
//     globalThis.store.dispatch(transportDriverActions.setDirty(true));
//     redirect.web('/network/driver');
//     return res;
//   } catch (err) {
//     utils.displayErrors(err);
//     throw err;
//   }
//   return false;
// }

// export const handleSearch = async (values, formikBag, dispatch) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: `Searching for ${values.mobile_number}`,
//       key: 'req-form',
//     });
//     const res = await dispatch(transportSearchDriverActions.fetchDriverByMobile(values));

//     if (!_.isEmpty(res)) {
//       notifications.show({
//         type: 'success',
//         message: 'Record Found',
//         key: 'req-form',
//       });
//     } else {
//       notifications.show({
//         type: 'success',
//         message: 'Record not Found',
//         key: 'req-form',
//       });
//     }
//     if (!_.isEmpty(res)) {
//       let driverId = 0;
//       _.map(res, (details, id) => {
//         if (values.mobile_number == details.mobile_number) {
//           driverId = details.id;
//         }
//       });

//       history.push(getUrlWithEncryptedQueryParams(`/network/driver/edit/${driverId}`));
//     } else {
//       // let driverId = 0;
//       // history.push(`/network/driver/create`);
//       history.push({
//         pathname: `/network/driver/create`,
//         state: { mobile_number: values.mobile_number },
//       });
//     }

//     return res;
//   } catch (err) {
//     console.log('handleSearch--->error---->', err);
//     utils.displayErrors(err, formikBag);
//   }
// };
// export const handleLink = async (values, formikBag) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Updating Driver Details...',
//       key: 'req-form',
//     });
//     const id = _.get(values, 'person_id') || 0;
//     const res = await driverService.link(id, values);
//     notifications.show({
//       type: 'success',
//       message: 'Driver details updated.',
//       key: 'req-form',
//     });

//     globalThis.store.dispatch(transportDriverActions.setDirty(true));
//     redirect.web('/network/driver');

//     return res;
//   } catch (err) {
//     console.log('handleLink--->error---->', err);
//     utils.displayErrors(err, formikBag);
//   }
// };
// export const handleEKycDocument = async (values, formikBag) => {
//   try {
//     notifications.show({
//       type: 'info',
//       message: 'Verifying Documents ...',
//       key: 'req-form',
//     });

//     const res = await driverService.eKycDocumentVerification(values);
//     notifications.show({
//       type: 'success',
//       message: _.get(res, 'kyc_message') || res,
//       key: 'req-form',
//     });

//     return res;
//   } catch (err) {
//     console.log('handleEKyc--->error---->', err);
//     utils.displayErrors(err, formikBag);
//   }
// };
