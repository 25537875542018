import { enableMapSet } from 'immer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import monitorReducersEnhancer from './enhancers'

// import { logger, crashReporter } from './middlewares'
// import rootReducer from './reducers'

import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from './storageProvider';

// mod
import reduxLogger from 'redux-logger';

import user from './user';
import misc from './misc';
import posts from './posts';
import requirements from './requirements';
import offers from './offers';
import bids from './bids';
import alert from './alert';
import referenceData from './referenceData';
import filterOptions from './filterOptions';
import requirmentOffers from './requirmentOffers';
import deals from './deals';
import feedbackUser from './feedbackUser';
import feedbackUserNew from './feedbackUserNew';
import redirect from './redirect';
import tripDetails from './tripDetails';

import transportRequirements from './transportRequirements';
import transportOffer from './transportOffer';
import transportDeals from './transportDeals';
import deliveryAssignment from './deliveryAssignment';

import socialProfiles from './socialProfiles';
import companies from './companies';
import indentEnabledCompanies from './indentEnabledCompanies';
import affiliates from './affiliates';
import farms from './farms';
import farmsTopbarLocations from './farmsTopbarLocations';
import locations from './locations';
import locationsTopBar from './locationsTopBar';
import transports from './transports';
import uploadDoc from './uploadDoc';
import uploadBanner from './uploadBanner';

import kycBusiness from './kycBusiness';
import kycIndividual from './kycIndividual';

import kycBusinessCompany from './companyAffiliates';
import deliveryScheduleMonth from './deliveryScheduleMonth';
import deliveryScheduleDecade from './deliveryScheduleDecade';
import deliveryScheduleDay from './deliveryScheduleDay';
import deliveryAllotments from './deliveryAllotments';

import kycUserTaxIdentity from './kycUserTaxIdentity';
import kycUserBankIdentity from './kycUserBankIdentity';
import kycBusinessTaxIdentity from './kycBusinessTaxIdentity';
import kycBusinessBankIdentity from './kycBusinessBankIdentity';
import banks from './bank';
import kycComments from './kycComments';

import teams from './teams';
import dealInvoices from './dealInvoices';
import deliveries from './deliveries';
import invoiceDetails from './invoicesDetails';
import kycApproval from './kycapproval';
import kycApprovalBusiness from './kycapprovalBusiness';
import context from './context';
import kycUser from './kycUser';
import transportDriver from './transportDriver';
import fuelPrice from './fuelPrice';
import fuelManagement from './fuelManagement';
import fuelDispensed from './fuelDispensed';
import fuelConfiguration from './fuelConfiguration';
import carnotDevice from './carnotDevice';
import cargo from './cargo';
import qualitySpec from './qualitySpec';
import deliveryPhases from './deliveryPhases';
import invoicePDF from './invoicesDetailsPDF';
import recordPayment from './recordPayment';
import transportVehicle from './transportVehicle';
import payments from './payments';
import paymentDetails from './paymentDetails';
import invoiceCustomer from './invoiceCustomer';
import generateReport from './generateReport';
import generateBuySellTrackerReport from './generateBuySellTrackerReport';
import generateReportAllData from './generateReportAllData';
import commodityPlan from './commodityPlan';
import commodityPlanDetails from './commodityPlanDetails';
import commodityAuction from './commodityAuction';
import commodityAuctionDetails from './commodityAuctionDetails';
import planOffers from './planOffers';
import planRequirements from './planRequirements';
import eKycStatus from './eKycStatus';
import deliveriesCT from './deliveriesCT';
import invoicesCT from './invoicesCT';
import rolesAndRights from './rolesAndRights';
import transportSearchDriver from './transportSearchDriver';

import deliveryContract from './deliveryContract';
import deliveryContractDetailDelivery from './deliveryContractDetailDelivery';
import hiringParty from './hiringParty';
import subPostType from './subPostType';
import hashtagForSocialfeed from './hashtagForSocialfeed';
import transportProvider from './transportProvider';
import deliveryContractDetails from './deliveryContractDetails';
import transporters from './transporters';
import kycRolesAndRights from './kycRolesAndRights';
import subscriptionDetails from './subscriptionDetails';
import subscription from './subscription';
import selectAllCheck from './selectAllCheck';

import notifications from './notifications';
import roles from './roles';
import followers from './followers';
import generateReportCommon from './generateReportCommon';
import userMention from './userMention';

import userStorage from './storage';
import storageTable from './storageTable';
import commodityAuctionLanding from './commodityAuctionLanding';
import commodityAuctionOfferBidDetails from './commodityAuctionOfferBidDetails';
import liveroomDeals from './liveroomDeals';
import configuration from './configuration';
import configurationAllVendors from './configurationAllVendors';
import teamLocation from './teamLocation';

import companyBusinessDetails from './companyBusinessDetails';
import companyConnections from './companyConnections';

import postLikedBy from './postLikedBy';
import postDetails from './postDetails';
import notificationsTopbar from './notificationsTopbar';
import platformCharges from './platformCharges';
import discountPlans from './discountPlans';
import discountSchemes from './discountSchemes';
import discountClaims from './discountClaims';
import discountPlanDetails from './discountPlansDetails';
import generateUpcomingDeliveryReport from './generateUpcomingDeliveryReport';
import generateCompletedDeliveryReport from './generateCompletedDeliveryReport';
import generateOngoingDeliveryReport from './generateOngoingDeliveryReport';
import dataSetup from './dataSetup';
import autorizedSignatory from './autorizedSignatory';
import authorizedSignatoryCompanies from './authorizedSignatoryCompanies';
import authorizedSignatoryUsers from './authorizedSignatoryUsers';
import loadingUnloading from './loadingUnloading';
import finance from './finance';
import financeInstalledCapacity from './financeInstalledCapacity';
import financePartner from './financePartner';
import financePartnerDetails from './financePartnerDetails';
import financeUser from './financeUser';
import financeUserDetails from './financeUserDetails';
import qualityService from './qualityServices';

import locationEloc from './locationEloc';
import locationElocDetails from './locationElocDetails';
import generateDeliveryReportRegular from './generateDeliveryReportRegular';
import financeChatMessages from './financeChatMessages';
import uploadQualityDoc from './uploadQualityDoc';
import qualityLocations from './qualityLocation';
import ratings from './ratings';
import qualityLabData from './qualityLabData';
import qualityObservedLabData from './qualityObservedLabData';
import counter from './counter';
import qualityServiceDetails from './qualityServiceDetails';
import extendedProfile from './extendedProfile';
import fetchExtendedProfilePhotos from './fetchExtendedProfilePhotos';
import financeBankNbfcDetails from './financeBankNbfcDetails';
import financeDocuments from './financeDocuments';
import financeProviderBank from './financeProviderBank';
import financeProviderCreditLimit from './financeProviderCreditLimit';
import sellerBank from './sellerBank';
import cashflow from './cashflow';
import cashflowNew from './cashflowNew';
import cashflowPreview from './cashflowPreview';
import dealBalancePosition from './dealBalancePosition';
import paymentEditDetails from './paymentEditDetails';
import farmerLocations from './farmerLocations';
import mapMyIndia from './mapActions';
import goodAndServices from './goodAndServices';
import manualInvoice from './manualInvoice';
import seller from './seller';
import buyer from './buyer';
import sellerAddress from './sellerAddress';
import buyerAddress from './buyerAddress';
import manualInvoiceDetails from './manualInvoiceDetails';
import searchPosts from './searchPosts';
import bannerAndFollowPreferences from './bannerAndFollowPreferences';
import ratingDetails from './ratingDetails';
import bfcPostDetails from './bfcPostDetails';
import subscriptionPassbook from './subscriptionPassbook';
import mySubscriptions from './mySubscriptions';
import subscriptionHistory from './subscriptionHistory';
import dashboard from './dashboard';
import autoOffer from './autoOffer';
import autoBidOffer from './AutoBidOffers';
import supplierRatings from './supplierRatings';
import transportRate from './transportRate';
import transportLocation from './transportLocation';
import members from './members';
import multipartyAuctions from './multipartyAuctions';
import rental from './equipmentRental';
import farmerOnboarding from './farmerOnboarding';
import commodityIndentDetails from './commodityIndentDetails';
import facilitator from './facilitator';
import printableInvoice from './printableInvoice';
import earlyPayments from './earlyPayments';
import multipartyAuctionsDetails from './multipartyAuctionsDetails';
import smartBuyerConfig from './smartBuyerConfig';
import MUIComponents from './MUIComponentReducers/MUIAutocompleteReducer';
import commodityExplore from './commodityExplore';
import linkedDealTracker from './linkedDealTracker';
import discoveryBuyersSellers from './discoveryBuyersSellers';
import system from './system';
import {
  cryptoTableReducer,
  newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  todoReducer,
  rtlReducer,
  authReducer,
} from './reducers/index';

import debug from './debug';
import upcomingDeliveries from './upcomingDeliveries';
import ongoingDeliveries from './ongoingDeliveries';
import completedDeliveries from './completedDeliveries';
import missedDeliveries from './missedDeliveries';
import pickupMap from './pickupMap';
import notConfirmedDeliveries from './notConfirmedDeliveries';
import notConfirmedDeliveriesCsvData from './notConfirmDeliveriesCsvData';
import aggregationPlanning from './aggregationPlanning';
import pickupOffers from './pickupOffers';

enableMapSet();

const rootReducer = combineReducers({
  // form: reduxFormReducer, // mounted under "form",
  // theme: themeReducer,
  // rtl: rtlReducer,
  // sidebar: sidebarReducer,
  // cryptoTable: cryptoTableReducer,
  // newOrder: newOrderTableReducer,
  // customizer: customizerReducer,
  // todos: todoReducer,
  // user: authReducer,
  user,
  notifications,
  companies,
  indentEnabledCompanies,
  affiliates,
  farms,
  farmsTopbarLocations,
  locations,
  locationsTopBar,
  transports,
  socialProfiles,
  requirements,
  offers,
  alert,
  bids,
  deals,
  feedbackUser,
  feedbackUserNew,
  posts,
  referenceData,
  filterOptions,
  requirmentOffers,
  transportRequirements,
  transportOffer,
  deliveryAssignment,
  transportDeals,
  redirect,
  misc,
  uploadDoc,
  uploadBanner,
  debug,
  kycBusiness,
  kycIndividual,
  kycBusinessCompany,
  deliveryScheduleMonth,
  deliveryScheduleDecade,
  deliveryScheduleDay,
  deliveryAllotments,

  kycUserTaxIdentity,
  kycUserBankIdentity,
  kycBusinessTaxIdentity,
  kycBusinessBankIdentity,
  banks,
  kycComments,
  teams,
  dealInvoices,
  deliveries,

  tripDetails,

  invoiceDetails,

  kycApproval,
  kycApprovalBusiness,
  context,
  kycUser,
  transportDriver,
  fuelPrice,
  fuelManagement,
  fuelDispensed,
  fuelConfiguration,
  carnotDevice,
  cargo,
  qualitySpec,
  deliveryPhases,
  invoicePDF,
  recordPayment,
  transportVehicle,
  payments,

  paymentDetails,

  invoiceCustomer,
  generateReport,
  generateBuySellTrackerReport,
  generateReportAllData,
  commodityPlan,
  commodityPlanDetails,
  commodityAuction,
  commodityAuctionDetails,
  planOffers,
  planRequirements,
  eKycStatus,
  deliveriesCT,
  invoicesCT,
  rolesAndRights,
  transportSearchDriver,
  deliveryContract,
  hiringParty,
  subPostType,
  hashtagForSocialfeed,
  transportProvider,
  deliveryContractDetails,
  deliveryContractDetailDelivery,
  transporters,
  roles,
  kycRolesAndRights,
  subscriptionDetails,
  subscription,
  followers,
  generateReportCommon,
  userMention,
  commodityAuctionLanding,
  selectAllCheck,
  userStorage,
  storageTable,
  commodityAuctionOfferBidDetails,
  liveroomDeals,
  configuration,
  configurationAllVendors,
  teamLocation,
  companyBusinessDetails,
  companyConnections,
  postLikedBy,
  postDetails,
  notificationsTopbar,
  platformCharges,
  discountPlans,
  discountSchemes,
  discountClaims,
  discountPlanDetails,
  generateUpcomingDeliveryReport,
  generateCompletedDeliveryReport,
  generateOngoingDeliveryReport,
  dataSetup,
  autorizedSignatory,
  authorizedSignatoryCompanies,
  authorizedSignatoryUsers,
  locationEloc,
  locationElocDetails,
  loadingUnloading,
  finance,
  financeInstalledCapacity,
  financePartner,
  financePartnerDetails,
  financeUser,
  financeUserDetails,
  generateDeliveryReportRegular,
  financeChatMessages,
  qualityService,
  uploadQualityDoc,
  qualityLocations,
  ratings,
  qualityLabData,
  qualityObservedLabData,
  counter,
  qualityServiceDetails,
  extendedProfile,
  fetchExtendedProfilePhotos,
  financeBankNbfcDetails,
  financeDocuments,
  financeProviderBank,
  financeProviderCreditLimit,
  sellerBank,
  cashflow,
  cashflowNew,
  cashflowPreview,
  dealBalancePosition,
  paymentEditDetails,
  farmerLocations,
  mapMyIndia,
  goodAndServices,
  manualInvoice,
  buyer,
  seller,
  buyerAddress,
  sellerAddress,
  manualInvoiceDetails,
  searchPosts,
  bannerAndFollowPreferences,
  ratingDetails,
  bfcPostDetails,
  subscriptionPassbook,
  mySubscriptions,
  subscriptionHistory,
  dashboard,
  autoOffer,
  autoBidOffer,
  supplierRatings,
  transportRate,
  transportLocation,
  members,
  multipartyAuctions,
  multipartyAuctionsDetails,
  rental,
  farmerOnboarding,
  commodityIndentDetails,
  facilitator,
  printableInvoice,
  earlyPayments,
  smartBuyerConfig,
  MUIComponents,
  commodityExplore,
  linkedDealTracker,
  discoveryBuyersSellers,
  missedDeliveries,
  upcomingDeliveries,
  ongoingDeliveries,
  completedDeliveries,
  pickupMap,
  notConfirmedDeliveries,
  notConfirmedDeliveriesCsvData,
  system,
  aggregationPlanning,
  pickupOffers,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // whitelist: ['user', 'filterOptions'],
  // whitelist: ['filterOptions'],
  whitelist: ['context'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function configureAppStore(preloadedState = {}) {
  const store = configureStore({
    reducer: persistedReducer,
    // middleware: [loggerMiddleware, ...getDefaultMiddleware()],
    /// middleware: [logger, reduxLogger, ...getDefaultMiddleware()],
    // middleware: [logger, ...getDefaultMiddleware()],
    middleware: [
      // reduxLogger,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
    // middleware: [crashReporter, reduxLogger, ...getDefaultMiddleware()],
    // middleware: [...getDefaultMiddleware()],
    preloadedState,
    // enhancers: [monitorReducersEnhancer]
  });

  // window.store=store

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  // module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  // }

  return store;
}

// const store = createStore(reducer);
const store = configureAppStore({});

globalThis.store = store;

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof globalThis.store.dispatch;
