import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as filterOptionsActions } from 'redux/filterOptions';
import _ from 'lodash';
import api from 'lib/api';

export default function CompanySelect(props) {
  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    variant,
    disabled,
    initialDisplayText,
    qparams,
    ...rest
  } = props;
console.log("QSPARAMS24", qparams)
  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);
  const dispatch = useDispatch();
  const filterOptions = useSelector((state) => state.filterOptions);

  options = _.get(filterOptions, 'data.packaging_code') || [];

  const fetchSomeText = options.filter((item, index) => item.text === 'Bales (Round)');

  const temp = _.get(fetchSomeText, '[0].value');

  let prevData;
  let prevDataArray0;
  let prevDataArray1;
  let prevDataArray2;

  let newOptionsArray;
  let newArray;

  if (temp === 11) {
    prevData = [...options];
    prevDataArray0 = prevData.slice(0, 2);
    prevDataArray1 = prevData.slice(2, 6);
    prevDataArray2 = prevData.slice(6);

    newOptionsArray = prevDataArray0.concat(prevDataArray2);
    newArray = newOptionsArray.concat(prevDataArray1);
    options = [...newArray];
  } else {
    options = options;
  }

  useEffect(() => {
    if (!_.isEmpty(qparams.commodity_type_code) && !_.isEmpty(qparams.commodity_form_code)) {
      dispatch(
        filterOptionsActions.getPackagingTypeAgainstTypeFormCode(
          qparams.commodity_type_code,
          qparams.commodity_form_code,
        ),
      );
      if (!options.length) {
        loading = true;
      }
    }
  }, [qparams?.commodity_type_code, qparams?.commodity_form_code]);

  useEffect(() => {
    if (!loading) {
      let initiaEntityId = _.get(props, 'form.values.packaging_id') || 0;
      const initialSelected = options.filter((o) => initiaEntityId === o.value);
      setValue(initialSelected);
    }
  }, [loading, qparams?.commodity_type_code, qparams?.commodity_form_code, values]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`aoi-${Date.now()}`}
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={loading || disabled}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].text`) || '';
          } else {
            return option.text || '';
          }
        }}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
