import React, { useEffect, useState } from 'react';
import Numpad from 'react-numpad';
import NumpadIcon from 'assets/images/icons/numpad.png';
import { Modal } from 'antd';

const NumpadInput = ({ setFieldValue, inputFieldName, placeholderValue, defaultValue }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleNumpadChange = (value) => {
    setInputValue(value);
    setFieldValue(inputFieldName, value);
    handleOk();
  };

  useEffect(() => {
    setInputValue(defaultValue.length ? parseInt(defaultValue) : inputValue);
  }, [defaultValue,isModalVisible]);

  return (
    <div>
      <img
        src={NumpadIcon}
        alt="Numpad Icon"
        style={{ height: '35px', width: '35px', cursor: 'pointer' }}
        onClick={showModal}
      />
      <Modal
        title={placeholderValue}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={400} // Set custom width
        placeholder="Press here"
      >
        <Numpad.Number
          onChange={handleNumpadChange} // Update field value
          decimal={false} // Prevent decimal input
          position="center" // Adjust position of the numpad
          value={inputValue}
        >
          <input
            style={{ fontSize: '20px', padding: '10px', width: '100%' }}
            placeholder={placeholderValue}
            value={inputValue}
            readOnly
          />
        </Numpad.Number>
      </Modal>
    </div>
  );
};

export default NumpadInput;
